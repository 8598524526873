import { API, graphqlOperation } from 'aws-amplify';


 const updateVesselInformation = `mutation updateVesselInfo($vessel_id: ID!, $details: InputVesselChangeVesselInformation) {
    VesselChangeVesselInformation(vessel_id: $vessel_id, details: $details) {
        name
    }
  }
  `;

// const updateUser = `mutation updateUser($first_name: String, $last_name: String, $email: String, $age: Int, $phone_number: String, $gender: String
//   $street: String, $street_2: String, $city: String, $post_code: Int, $state: String, $country: String
// ){
//   updateUser(input:{
//     id: "5c4d950d-bbba-4fe0-8d6a-1e90700c8879"
//   }){
//     name
//   }
// }`;

const getVesselEngines = `query getVesselEngines($vessel_id: ID!) {
    EngineGetEngines(vessel_id: $vessel_id){
        id
        brand
        horse_powers
        model_name
        year_manufactured
        displacement
        full_throttle_rpm
    }
}`

const getVesselLocation = `query getVesselLocation($vessel_id: ID!) {
    VesselGetLocation(vessel_id: $vessel_id){
        latitude
        longitude
    }
}`

const getUserVessels = `query getUserInfo {
    UserGetUser{
        vessels{
            id
            name
            vessel_type
            length
            beam
            brand
            year_manufactured
            home_harbour{
                harbour_name
                street
                street_2
                city
                state
                post_code
                country
            }
            engines{
                id
                brand
                horse_powers
                model_name
                year_manufactured
                displacement
                full_throttle_rpm
            }
        }
    }
}`

const servicePortalGetVesselForUser = `query getVesselInfo($details: InputServicePortalGetVesselForUser!) {
    ServicePortalGetVesselForUser (details: $details){
        id,
        vessel_picture{
            key,
            url,
            download_url
        },
        users{
            id,
            first_name,
            last_name
        },
        friends{
            id,
            first_name,
            last_name,
            email
        },
        fobs{
            id,
            key,
            name,
            region,
            product_type,
            fob_type,
            serial_number,
            auto_connect_on,
            firmware_version{
                software_version
            }
        },
        hubs{
            id,
            key,
            serial_number,
            region,
            product_type,
            firmware_version{
                software_version
            }
        },
        engines{
            id,
            brand,
            type,
            horse_powers,
            model_name,
            year_manufactured,
            displacement,
            full_throttle_rpm,
        },
        transfer_user{
            id,
            first_name,
            last_name,
            email,
            phone_number,
            accepted
        },
        name,
        vessel_type,
        length,
        beam,
        brand,
        year_manufactured,
        region,
        product_type,
        smartcraft_compatible,
        tds_settings{
            tds_active,
            unlock_setting,
            tds_estop,
            tds_compatible
        }
        organization{
            id,
            name
        },        
        last_sync_time,
        latest_tds_status{
            id,
            value,
            longitude,
            latitude,
            time_stamp
        },
        home_harbour{
            id,
            harbour_name,
            street,
            street_2,
            city,
            state,
            post_code,
            country,
        }
    }
}`

const servicePortalSearch = `query getVesselSearch($details: InputServicePortalSearch!) {
    ServicePortalSearch (details: $details){
        user_id,
        vessel_id
    }
}`

export default class VesselService{
    constructor(){

    }

    static async getUserVessels(){
        const response = await API.graphql(graphqlOperation(getUserVessels));
        console.log('response', response)
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.UserGetUser.vessels;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async updateVesselInformation(vesselInfo){
        let success = true;
        let data = null;
        let error = "";
        try{
            const response = await API.graphql(graphqlOperation(updateVesselInformation, {vessel_id: vesselInfo.id, details: this.toUpdatedObject(vesselInfo)}));
            data = response.data;
        }
        catch(ex){
            success = false;
            error = ex.errors[0].message;
            console.log(error);
        }
         return {
             success: success,
             data: data,
             error: error
         }
        // return response;
    }

    static async getVesselEngines(vessel_id){
        const response = await API.graphql(graphqlOperation(getVesselEngines, {vessel_id: vessel_id}));
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.EngineGetEngines;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async getVesselLocation(vessel_id){
        const response = await API.graphql(graphqlOperation(getVesselLocation, {vessel_id: vessel_id}));
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.VesselGetLocation;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async getServicePortalGetVesselForUser(user_id, vessel_id){
        const response = await API.graphql(graphqlOperation(servicePortalGetVesselForUser, {details: {user_id, vessel_id}}));
        console.log('fetched vessel:', response);
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.ServicePortalGetVesselForUser;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async getServicePortalSearch(device_serial_number){        
        const response = await API.graphql(graphqlOperation(servicePortalSearch, {details: {device_serial_number}}));
        console.log('fetched vessel search:', response);
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.ServicePortalSearch;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static checkForNull(userData){
        for (var name in userData) {
          userData[name] = userData[name] != null ? userData[name] : "";
        }
        return userData;
      }

    static toEditObject(vesselData){
        const vesselEditModel = {
            id: vesselData.id,
            name: vesselData.name,
            length: vesselData.length,
            beam: vesselData.beam,
            brand: vesselData.brand,
            year_manufactured: vesselData.year_manufactured != 0 ? vesselData.year_manufactured : "",
            vessel_type: vesselData.vessel_type,
            home_harbour_name: vesselData.home_harbour.harbour_name,
            home_harbour_street: vesselData.home_harbour.street,
            home_harbour_street_2: vesselData.home_harbour.street_2,
            home_harbour_city: vesselData.home_harbour.city,
            home_harbour_state: vesselData.home_harbour.state,
            home_harbour_country: vesselData.home_harbour.country,
            home_harbour_post_code: vesselData.home_harbour.post_code,
        }
        return this.checkForNull(vesselEditModel);
    }

    static toUpdatedObject(vesselEditModel){
        const dtoObject = {
            name: vesselEditModel.name,
            length: vesselEditModel.length,
            beam: vesselEditModel.beam,
            brand: vesselEditModel.brand,
            year_manufactured: vesselEditModel.year_manufactured != "" ? vesselEditModel.year_manufactured : 0,
            vessel_type: vesselEditModel.vessel_type,
            harbour_name: vesselEditModel.home_harbour_name,
            street: vesselEditModel.home_harbour_street,
            street_2: vesselEditModel.home_harbour_street_2,
            city: vesselEditModel.home_harbour_city,
            state: vesselEditModel.home_harbour_state,
            post_code: vesselEditModel.home_harbour_post_code,
            country: vesselEditModel.home_harbour_country,
        };
        // console.log(dtoObject.type);
        return dtoObject;
    }

    static dataHandler(response){

        console.log(response);

    }
}