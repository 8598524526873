import { Button, TextField } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import blueFormStyles from '../../../common-styles/js/blue-form-style'
import { withStyles } from '@material-ui/core/styles';
import {validateEmail, validateDealerNumber} from '../../../utils/validators'
import UserService from '../../../services/UserService'
import './validation-form.css';
import Spinner from './../../spinner/spinner';
import config from '../../../appconfig';
import Amplify from 'aws-amplify';

function ValidationForm({classes, onShowSuccessChange}) {

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    // REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: config.userPoolId,
    // REQUIRED - Amazon Cognito Region
    region: config.region,
    // OPTIONAL - Amazon Cognito User Pool ID
    identityPoolId: config.identityPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.userPoolWebClientId,
  },
  API: {
    'aws_appsync_graphqlEndpoint': config.graphqlEndpoint,
    'aws_appsync_region': 'eu-central-1',
    'aws_appsync_authenticationType': 'AWS_IAM',
  },
});

  const [email, setEmail] = useState('')  
  const [emailError, setEmailError] = useState('Email is required')
  const [emailDirty, setEmailDirty] = useState(false)

  const [dealerNumber, setDealerNumber] = useState('')
  const [dealerNumberError, setDealerNumberError] = useState('Dealer number is required')
  const [dealerNumberDirty, setDealerNumberDirty] = useState(false)

  const [companyName, setCompanyName] = useState('')
  const [companyNameDirty, setCompanyNameDirty] = useState(false)

  const [formValid, setFormValid] = useState(false)

  const [formEror, setFormError] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  
  useEffect(() => {
    if(emailError || dealerNumberError){
      setFormValid(false)
    }
    else{
      setFormValid(true)
    }

  }, [emailError, dealerNumberError])

  function emailHandler(email){
    email = email.trim()
    setEmail(email);

    if(email.length <=0 ){
      setEmailError('Email is required');
    }
    else if(!validateEmail(email)){
      setEmailError('Email invalid');      
    }
    else{
      setEmailError('');
    }    
  }

  function dealerNumberHandler(dealerNumber){
    dealerNumber = dealerNumber.trim();
    setDealerNumber(dealerNumber);
    
    if(dealerNumber.length <= 0 ){
      setDealerNumberError('Dealer number required');
    }
    else if(!validateDealerNumber(dealerNumber)){
      setDealerNumberError('Dealer number must contains digits only');
    }
    else{
      setDealerNumberError('');
    }
  }

  function companyNameHandler(companyName){
    setCompanyName(companyName);
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError('');
    setIsLoading(true);

    UserService.OemSelfRegister(email, dealerNumber, companyName)
    .then(response => {
        if(response.success){
          if(response.data.succesfully_registered == true){
            onShowSuccessChange(true)
          }
          else{
            console.table(response.data.errors);
            setFormError(`Validation failed: ${response.data.errors[0].message}`);
          }
        }
        else{
          console.log('error');
        }
      })
      .catch(ex => {
        console.log(ex.message);
        setFormError('Could not chek data. Please, try again later.');
      })
      .finally(()=>{
        setIsLoading(false);
      })
  }
  
  function spinnerContainer(){
    return (
      <div className='spinner-container'>
        <Spinner/> 
      </div>    
    )

  }

    return (
        <form className="validation-form">
            <div className="group">
                <TextField
                id="emailToValidate"
                label="Email address"
                name="emailToValidate"
                value={email}
                onChange={e => emailHandler(e.target.value)}
                onBlur={e => setEmailDirty(true)}
                error={emailError && emailDirty ? true : false}
                helperText={emailDirty && emailError}
                className={classes.textField}
                InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssLabelFocused,
                      error: classes.cssLabelError
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssInput,
                      focused: classes.cssLabelFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.underline
                    },
                  }}
                />
            </div>
            <div className="group">
                <TextField
                id="dealerNumber"
                label="Mercury dealer number"
                name="dealerNumber"
                className={classes.textField}
                value={dealerNumber}
                onChange={e=> dealerNumberHandler(e.target.value)}
                onBlur={e=> setDealerNumberDirty(true)}
                error={dealerNumberError && dealerNumberDirty ? true : false} 
                helperText={dealerNumberDirty && dealerNumberError}
                InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssLabelFocused,
                      error: classes.cssLabelError
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssInput,
                      focused: classes.cssLabelFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.underline
                    },
                  }}
                />
            </div>
            <div className="group">
                <TextField
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  className={classes.textField}
                  value={companyName}
                  onChange={e=> companyNameHandler(e.target.value)}
                  onBlur={e=> setCompanyNameDirty(true)}
                  InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssLabelFocused,
                        error: classes.cssLabelError
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssInput,
                        focused: classes.cssLabelFocused,
                        notchedOutline: classes.notchedOutline,
                        underline: classes.underline
                      },
                    }}
                />
            </div>
            <div className="group">
                <a href='#' className='form-link'>Reset password</a>
            </div>
            <div className="form-error">{formEror}</div>
            <div className="group">
                <button className='btn form-button' onClick={formSubmit} disabled={!formValid} >Verify</button>
                {formValid}
            </div>
            {isLoading && spinnerContainer()}                    
        </form>
    )
}

export default withStyles(blueFormStyles)(ValidationForm)
