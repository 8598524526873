import React, {useState} from 'react'
import './validation-page.css'
import newLogo from '../../../img/logo1.png'
import ValidationFormContainer from './../validation-form-container';
import ValidationSuccessContainer from '../validation-success-container/validation-success-container';

function ValidationPage() {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)


    return (
        <div className="validation-page">
            <div className="main-content container">
                <div className="row justify-content-center">
                    <div className="new-logo">
                        <img src={newLogo} alt="brand"/>
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-lg-5">
                        {showSuccessMessage ? <ValidationSuccessContainer/>:<ValidationFormContainer onShowSuccessChange={setShowSuccessMessage} />} 
                    </div>
                    
                </div>
                

            </div>
        </div>
    )
}

export default ValidationPage;
