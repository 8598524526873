import { API, graphqlOperation } from 'aws-amplify';

const getUserInformation = `query getUserInfo {
    UserGetUser{
        id
        first_name
        last_name
        email
        age
        phone_number
        gender
        street
        street_2
        city
        post_code
        state
        country
    }
}`

const getCurrentUserId = `query getUserInfo {
    UserGetUser{
        id
    }
}`

const updateUserInformation = `mutation updateUserInfo($details: InputUserChangeUserDetails) {
    UserChangeUserInformation(details: $details) {
        first_name
        age
    }
  }
  `;

  const oemSelfRegister = `mutation setOemSelfRegister($details: InputOemSelfRegister) {
    OemSelfRegister(details: $details) {
        succesfully_registered,
        errors{
            ... on OemNameNotProvided {
                message
            }
            ... on UnknownDealerNumberError {
                message
            }
            ... on EmailAlreadyRegisteredWithOem {
                message
            }
        }
    }
  }
  `;

  const getServicePortalUsers= `query getUserInfo {
    ServicePortalListUsers{
        id,
        first_name,
        last_name,
        email
    }
}`

const getServicePortalGetUser = `query getUserInfo($details: InputServicePortalGetUser!) {
    ServicePortalGetUser (details: $details){
        id,
        first_name,
        last_name,
        email,
        age,
        phone_number,
        gender,
        street,
        street_2,
        city,
        post_code,
        state,
        country,
        profile_picture{
            key,
            url,
            download_url
        },
        vessels{
            id,
            name
        }
    }
}`

const servicePortalGetLogForUser = `query getUserInfo($details: InputServicePortalGetLogForUser!) {
    ServicePortalGetLogForUser (details: $details){
        items{
            skey,
            category,
            device,
            device_id,
            os,
            app_version,
            vessel,
            message
        },
        nextToken
    }
}`


export default class UserService{

    static getCurrentIdSync(){
        let currentUserId = localStorage.getItem('currentUserId');
        if(currentUserId == null){
            API.graphql(graphqlOperation(getCurrentUserId)).then(
                response => {
                    console.log('response - ', response);
                }
            ).catch(ex => console.log(ex));
            // if(response.data.errors){
            // }
            // else{
            //     currentUserId = response.data.UserGetUser.id;
            //     localStorage.setItem('currentUserId',currentUserId);
            // }
        }
        // return currentUserId;
    }

    static async getCurrentUserId(){

        let currentUserId = localStorage.getItem('currentUserId');
        if(currentUserId == null){
            const response = await API.graphql(graphqlOperation(getCurrentUserId));
            if(response.data.errors){
            }
            else{
                currentUserId = response.data.UserGetUser.id;
                localStorage.setItem('currentUserId',currentUserId);
            }
        }
        return currentUserId;
    }

    static async getUserData(){
        const response = await API.graphql(graphqlOperation(getUserInformation));
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.UserGetUser;
            localStorage.setItem('currentUserId',data.id);
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async updateUser(userInfo){
        const userDto = this.toUpdateModel(userInfo);

        let success = true;
        let data = null;
        let error = "";
        try{
            const response = await API.graphql(graphqlOperation(updateUserInformation, {details: userDto}));
            data = response.data;
        }
        catch(ex){
            success = false;
            error = ex.errors[0].message;
            console.log(error);
        }
        return {
            success: success,
            data: data,
            error: error
        }   
    }

    static async getServicePortalListUsers(){
        const response = await API.graphql(graphqlOperation(getServicePortalUsers));
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.ServicePortalListUsers;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async getServicePortalGetUser(user_id, email){
        const response = await API.graphql(graphqlOperation(getServicePortalGetUser, {details: {user_id: user_id, email: email}}));
        console.log('fetched user:', response);
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.ServicePortalGetUser;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async getServicePortalGetLogForUser(user_id, nextToken){
        const response = await API.graphql(graphqlOperation(servicePortalGetLogForUser, {details: {user_id: user_id, nextToken: nextToken}}));
        console.log('fetched user log:', response);
        let success = true;
        let data = null;
        let error = "";
        if(response.data.errors){
           success = false;
           error = response.data.errors[0].message;
        }
        else{
            data = response.data.ServicePortalGetLogForUser;
        }
        return {
            success: success,
            data: data,
            error: error
        }
    }

    static async OemSelfRegister(email, dealerNumber, companyName){
        let success = true;
        let data = null;
        let error = "";
        console.log('inside function')
        try{
            
            // const result = await client.mutate({
            //     mutation: gql(oemSelfRegister),
            //     variables: {
            //         details: {
            //         email,
            //         dealer_number:dealerNumber
            //       }
            //     }
            //   });
            // console.log(result)
            // const response = await API.graphql(graphqlOperation(oemSelfRegister, {details: {email, dealer_number:dealerNumber}}),);
            const response = await API.graphql({
                query: oemSelfRegister,
                variables: {details: {email, dealer_number:dealerNumber, company_name: companyName}},
                authMode: 'AWS_IAM'
            });
            console.log('response - ', response)
            // const response = {data: {succesfully_registered : false, errors:[]}}
            data = response.data.OemSelfRegister;
        }
        catch(ex){
            success = false;
            error = ex
            console.log(error);
        }
        return {
            success: success,
            data: data,
            error: error
        }   
    }

    static async OemSelfRegisterTest(email, dealerNumber){
        let success = true;
        let data = null;
        let error = "";
        try{            
            // const response = await API.graphql(graphqlOperation(oemSelfRegister, {details: {email, dealer_number:dealerNumber}}));
            const response = {data: {succesfully_registered : false, errors:[]}}
            data = response.data.OemSelfRegister;
        }
        catch(ex){
            success = false;
            error = ex.errors[0].message;
            console.log(error);
        }
        return new Promise((resolve, reject)=>{
            setTimeout(()=>{
                if(Math.random() >= 0.75){
                    reject(new Error('test error'));
                }
                else{
                    resolve({
                        success: success,
                        data: data,
                        error: error
                    });
                }
                
            },2000)
        })
    }

    static toUpdateModel(userInfo){
        const dtoObject = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            email: userInfo.email,
            age: userInfo.age,
            phone_number: userInfo.phone_number,
            gender: userInfo.gender,
            street: userInfo.street,
            street_2: userInfo.street_2,
            city: userInfo.city,
            post_code: userInfo.post_code,
            state: userInfo.state,
            country: userInfo.country,
        };
        console.log(dtoObject);
        return dtoObject;
    }

    static dataHandler(response){

        console.log(response);

    }
}



// const updateUser = `mutation updateUser($first_name: String, $last_name: String, $email: String, $age: Int, $phone_number: String, $gender: String
//   $street: String, $street_2: String, $city: String, $post_code: Int, $state: String, $country: String
// ){
//   updateUser(input:{
//     id: "5c4d950d-bbba-4fe0-8d6a-1e90700c8879"
//     first_name: $first_name
//     last_name: $last_name,
//     email: $email,
//     age: $age,
//     phone_number: $phone_number,
//     gender: $gender,
//     street: $street,
//     street_2: $street_2,
//     city: $city,
//     post_code: $post_code,
//     state: $state,
//     country: $country,
//   }){
//     id
//     first_name
//     last_name
//     email
//     age
//     phone_number
//     gender
//     street
//     street_2
//     city
//     post_code
//     state
//     country
//   }
// }`;
