import React from "react";
import { SignIn, Greetings, ForgotPassword } from "aws-amplify-react";
import CustomSignIn  from "./CustomSignIn";
import CustomForgotPassword from "./CustomForgotPassword"
import App from "../../App";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Authenticator } from "aws-amplify-react/dist/Auth";
import AppConfig from '../../appconfig';
import Header from './../header/header';
import ValidationPage from "../user-validation/validation-page/validation-page";

class AppWithAuth extends React.Component {

  render() {
    const map = (message) => {
      if (/incorrect.*username.*password/i.test(message)) {
          return 'Incorrect username or password';
      }
  
      return message;
    }

    return (
      <div>
        <Router>
          <Switch>
            <Route exact path={["/temporary-blocked-root-path","/vessel","/user-list", "/user-details/:id", "/user-details/:id/vessel/:vessel_id", "/user-log/:id", "/vessel-search"]}>
              <Header/>
              <Authenticator hide={[SignIn, ForgotPassword, Greetings]} amplifyConfig={productionConfig} errorMessage={map}>
                <CustomSignIn />
                <CustomForgotPassword />
                <App />
              </Authenticator>
            </Route>
            <Route exact path="/">
              <ValidationPage/>
            </Route>
            
          </Switch>

        </Router>

      </div>
    );
  }
}

export default AppWithAuth;


const productionConfig = {
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: AppConfig.identityPoolId,
    
    // REQUIRED - Amazon Cognito Region
    region: AppConfig.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: AppConfig.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: AppConfig.userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: new MyStorage(),
    
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  aws_appsync_graphqlEndpoint: AppConfig.graphqlEndpoint,
  aws_appsync_region: AppConfig.appsyncRegion,
  aws_appsync_authenticationType: AppConfig.appsyncAuthenticationType,
  // aws_appsync_apiKey: 'xxxx'
};
