import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import boatImage from '../../img/ic_directions_boat_24px@2x.png';

class AccountMenu extends Component{

      render(){
        return  <div className="nav-side-menu col-lg-2">
                      <div className="menu-list">
                        <ul id="menu-content-nav" className="menu-content collapse out">
                            <li className="empty-item"></li>
                            <li>                               
                                <NavLink exact activeClassName="active" to="/">My Account</NavLink> 
                            </li>               
                            <li>
                                <NavLink activeClassName="active" to="/user-list">User List</NavLink> 
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/vessel-search">Vessel Search</NavLink> 
                            </li>
                        </ul>
                    </div>
                </div>
      }
}

export default withRouter(AccountMenu);



