import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router';
import UserService from '../../../../services/UserService';
import { NotificationManager } from 'react-notifications';
import TableLog from '../table-log/table-log';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomGreetings from './../../../Login/CustomGreetings';

function UserLog() {

    const [logs, setLogs] = useState([]);
    const [nextToken, setNextToken] = useState(null)
    const [loading, setLoading] = useState(false);
    const [logsWithVesselLink, setLogsWithVesselLink] = useState([]);

    const params = useParams();
    

    const generateLogsWithVesselLink = (arr) => {
      return arr.map(el => {
          return {category:el.category, device:el.device, message:el.message, skey: el.skey, vessel_link: <Link to={`/user-details/${params.id}/vessel/${el.vessel}`}>User vessel</Link>}
      })
    }


    const nextPage = async () => {
      console.log('next token', nextToken);
      try{
        const response = await UserService.getServicePortalGetLogForUser(params.id, nextToken);
        console.log('logs', response)
        if(response.success && response.data != null){
          setLogs(response.data.items)
          setNextToken(response.data.nextToken);
        }
        else{
            NotificationManager.error("Couldn't fetch user log. Please, try again later.", 'Error', 2000);
        }
      }
      catch(err){
        console.log(err)
        NotificationManager.error("Couldn't fetch user log. Please, try again later.", 'Error', 2000);
      }
      finally{
        setLoading(false);
      }
    }

    useEffect(() => {
        async function fetchLog() {
            setLoading(true);
            try{
              const response = await UserService.getServicePortalGetLogForUser(params.id, nextToken);
              console.log('logs', response)              
              if(response.success && response.data != null){
                setLogs(response.data.items)
                setNextToken(response.data.nextToken);    
                const logsWithVesselsLink = generateLogsWithVesselLink(response.data.items);
                setLogsWithVesselLink(logsWithVesselsLink);
              }
              else{
                  NotificationManager.error("Couldn't fetch user log. Please, try again later.", 'Error', 2000);
              }
            }
            catch(err){
              console.log(err)
              NotificationManager.error("Couldn't fetch user log. Please, try again later.", 'Error', 2000);
            }
            finally{
              setLoading(false);
            }
          }
          fetchLog()
    }, [])

    

    const tableColumns = [
        {
          Header: 'Category',
          accessor: 'category'
        },
        {
          Header: 'Device',
          accessor: 'device'
        },
        {
          Header: 'Message',
          accessor: 'message'
        },
        {
          Header: 'Date',
          accessor: 'skey'
        },
        {
          Header: 'Vessel',
          accessor: 'vessel_link'
        }      
      ];
         
    return (
        <div className="account-main col-lg-10">           
            <div className="account-header">
                <div className="sign-out-block">
                    <CustomGreetings/>
                </div>                
                <div className="cabinet-title d-flex">
                    <h3>User log</h3>
                    <div className="breadcrumbs-block">
                    <Button variant="contained" color="primary" style={{width:'auto', marginLeft:'20px'}} component={Link} to={`/user-details/${params.id}`}>
                        {'< Back to user'}
                    </Button>                    
                    </div>
                </div>
            </div>
            {loading && "Loading..."}
            {!loading && logs.length<1 && "Couldn't fetch user log."}
            {!loading && logs.length>0 && <TableLog columns={tableColumns} data={logsWithVesselLink} />}
        </div>
    )
}

export default UserLog

