import React, { Component } from 'react';
// import fishingImage from '../../img/fishing.jpg';
import CustomGreetings from '../Login/CustomGreetings';
// import CustomSignOut from '../Login/CustomSignOut';
import EditUserInfo from './edit-user-info';
import UserTrips from './user-trips';

class UserInfo extends Component{

      render(){
        return  <div className="account-main col-lg-10">
                    <div className="account-header">
                        <div className="sign-out-block">
                            {/* <a href="#">Sign out</a> */}
                            <CustomGreetings/>
                        </div>
                        <div className="cabinet-title">
                            <h3>My Account</h3>
                        </div>
                    </div>
                    {/* <div className="avatar-container">
                        <div className="avatar-img">
                        
                            <img src={require("../../img/fishing-guy.png")} alt="User Avatar" />
                        </div>
                        <div className="trips-info">
                            <div className="trips-title">Captain</div>
                            <div className="trips-count">
                                <span>Number of trips:</span> 178
                            </div>
                        </div>
                    </div> */}
                    <div className="main-content">
                        <EditUserInfo />
                        {/* <UserTrips /> */}
                    </div>
                </div>
      }
}

export default UserInfo;



