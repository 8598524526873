import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import marker from '../../img/App_SymbolBoat.png';
import AppConfig from '../../appconfig';

export class MapContainer extends Component {
    render() {
      const location = this.props.location;
      return (
        <Map google={this.props.google} zoom={14}
        initialCenter={{
            lat: location.latitude,
            lng: location.longitude
          }}
        >
   
        <Marker
            name={'Your position'}
                position={{lat: location.latitude, lng: location.longitude}}
            icon={{
                url: marker,
            }}
             />
        </Map>
      );
    }
  }
   
  export default GoogleApiWrapper({
    apiKey: AppConfig.googleMapAPIKey
  })(MapContainer)