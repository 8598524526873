import React, { Component } from 'react';

class Footer extends Component{
      render(){
        return       <footer>
        <div className="container footer-links">
          <a href="#">Learn more</a>|<a href="#">Support Center</a>
        </div>
      </footer>
      }
}

export default Footer;



