import React, { Component } from 'react';
import './header.css';

class Header extends Component{

      render(){
        return<div>
            <nav className="navbar navbar-expand-lg site-nav">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <i className="fas fa-bars"></i>
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="headerMenu">
                    <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className="nav-item mr-3">
                            <a className="nav-link" href="/"><i className="far fa-newspaper"></i> News</a>
                        </li>
                        <li className="nav-item mr-3">
                            <a className="nav-link" href="/"><i className="fas fa-info-circle"></i> Service & Support</a>
                        </li>
                        <li className="nav-item mr-3">
                            <a className="nav-link" href="/"><i className="fas fa-map-marker-alt"></i> Find a Dealer</a>
                        </li>
                        <li className="nav-item mr-3">
                            <a className="nav-link" href="/"><i className="fas fa-envelope"></i> Contact</a>
                        </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <i className="fas fa-search"></i>
                        <input id="headerSearch" className="form-control" type="search" placeholder="Search" aria-label="Search" autoComplete="off" />
                    </form>
                </div>
            </nav>
            <header id="brandHeader">
                <div className="header-brand"></div>
            </header>
        </div> 
      }
}

export default Header;



