const formStyles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      'font-size': '1.2rem',
    },
  
    cssLabel: {
      color : 'white',
      'font-size': '1.2rem',
      "&:focused:not($disabled):not($error)":{
        "color":"white"
      },
      "&.focused": {
        color: "red"
      }
    },
    cssLabelFocused: {
      "color":"white !important",
      "&:focused:not($disabled):not($error)":{
        "color":"white"
      }
    },

    cssLabelError:{
        color: "red !important"
    },
    
    disabled:{},
    error :{},
    focused:{},

    cssOutlinedInput: {
      // '&$cssFocused $notchedOutline': {
      //   borderColor: `${theme.palette.primary.main} !important`,
      // }
    },
  
    cssInput: {
      color: 'white',
      'font-size':'1.2rem'
    },
  
    cssFocused: {},
  
    // notchedOutline: {
    //   borderWidth: '1px',
    //   borderColor: 'green !important'
    // },
    underline: {
          color: theme.palette.common.white,
          borderBottom: theme.palette.common.white,
          '&:after': {
              borderBottom: `2px solid white`,			
          },				
          '&:focused::after': {
              borderBottom: `2px solid white`,
          },		
          '&:before': {
              borderBottom: `2px solid white`,			
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
              borderBottom: '2px solid white !important',
          },
          '&$disabled:before': {
              borderBottom: `2px dotted white`,
          },
      },
  })

  export default formStyles;