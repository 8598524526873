import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import formStyles from '../../common-styles/js/light-input-styles'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import {usaStates} from '../../constants';
import {countries} from '../../constants';
import * as constants from '../../constants';
import UserService from '../../services/UserService';
import { NotificationManager } from 'react-notifications';
import Auth from '@aws-amplify/auth';
import InputMask from 'react-input-mask';

const styles = formStyles;

const initializedUser = {
  first_name: "",
  last_name: "",
  email: "",
  age: 1,
  phone_number: "",
  gender: "",
  street: "",
  street_2: "",
  city: "",
  post_code: 0,
  state: "",
  country: "",
}

const hiddenInputActions = {
  first_name: false,
  last_name: false,
  email: false,
  phone_number: false,
  street: false,
  street_2: false,
  city: false,
  post_code: false,
}

class EditUserInfo extends Component{
    someInfo = initializedUser;
      state = {
        countryStates: constants.usaStates,
        countries: countries,
        oldUserInfo: initializedUser,
        
        newUserInfo: initializedUser,
        showInputActions : Object.assign({}, hiddenInputActions),
        validFields:{
          email:true
        }
      };
      constructor(props){
        super(props);

        this.inputInfoFocusedOut = this.inputInfoFocusedOut.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.userInfoChange = this.userInfoChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
      }

      componentDidMount() {
          document.addEventListener('click', this.handleOutsideClick);
      }
      
      componentWillUnmount() {
          document.removeEventListener('click', this.handleOutsideClick);
      }

      handleOutsideClick(event) {
          if(!event.target.classList.contains('interaction-elem')){
              this.hideAllActions();
          }
      }

      
      validateField(fieldName, value){
        let valid = true;
        switch(fieldName){
          case 'email':
            const matches = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            valid = matches ? true : false;
            break;
          default:
            break;
        }
        let validFields = this.state.validFields;
        validFields[fieldName] = valid;
        this.setState({validFields: validFields});
      }

      async componentWillMount(){
        const user= await UserService.getUserData();
        console.log(user.data);
        // this.setState({oldUserInfo: data.data.UserGetUser})
        if(user.success && user.data != null){
          const userData = this.checkForNull(user.data);
          // this.checkUserState(userData.state, userData.country);
          this.setState({oldUserInfo: userData});
          this.setState({newUserInfo: Object.assign({}, userData)});
        }
      }
      userInfoChange (event){
        let updatedInfo = this.state.newUserInfo;
        updatedInfo[event.target.name] = event.target.value;
        this.setState({ newUserInfo: updatedInfo});

        this.validateField(event.target.name, event.target.value);
      }
      inputInfoFocusedOut(e){
        // e.preventDefault();
        // e.stopPropagation();
      }
      async applyChanges(event){
        event.preventDefault();
        event.stopPropagation();
        const changingParam = event.currentTarget.getAttribute("changes-for");

        if(this.state.validFields[changingParam] != null && this.state.validFields[changingParam] == false){
          return false;
        }
        let updatedUserInfo = this.state.oldUserInfo;
        updatedUserInfo[changingParam] = this.state.newUserInfo[changingParam];
        const responseData = await UserService.updateUser(updatedUserInfo);
        if(responseData.success != null){
          this.setState({ oldUserInfo: updatedUserInfo});
          this.hideActions(changingParam);
          NotificationManager.success('Your changes applied successfully!', 'Success',1000);
        }
        else{
          NotificationManager.error("Couldn't update information. Try later.", 'Error',2000);
        }
      }
      discardChanges = event =>{
        event.preventDefault();
        event.stopPropagation();
        const changingParam = event.currentTarget.getAttribute("changes-for");
        let updatedUserInfo = this.state.newUserInfo;
        updatedUserInfo[changingParam] = this.state.oldUserInfo[changingParam];
        this.setState({ newUserInfo: updatedUserInfo});
        this.hideActions(changingParam);

        this.validateField(changingParam, updatedUserInfo[changingParam])

      }
      userInputFocus = event =>{
        this.showActions(event.target.name);
      }


      async onCountryChange(event){
        let updatedInfo = this.state.newUserInfo;
        if(event.target.value == "United States"){ 
          updatedInfo["state"] = constants.usaStates[0];
        }
        else{
          // this.setState({countryStates: ["Another Country"]});
          // updatedInfo["state"] = "Another Country";
        }
        updatedInfo[event.target.name] = event.target.value;
        this.setState({ newUserInfo: updatedInfo});

        let updatedUserInfo = this.state.oldUserInfo;
        updatedUserInfo["state"] = this.state.newUserInfo["state"];
        updatedUserInfo["country"] = this.state.newUserInfo["country"];
        const responseData = await UserService.updateUser(updatedUserInfo);
        if(responseData.data != null){
          this.setState({ oldUserInfo: updatedUserInfo});
          NotificationManager.success('Your changes applied successfully!', 'Success',1000);
        }
        else{
          NotificationManager.error("Couldn't update information. Try later.", 'Error',2000);
        }
      }

      showActions(param){
        let showActions = Object.assign({}, hiddenInputActions)
        showActions[param] = true;
        this.setState({showInputActions : showActions});
      }
      hideActions(param){
        let showActions = this.state.showInputActions;
        showActions[param] = false;
        this.setState({showInputActions : showActions});
      }
      hideAllActions(){
        this.setState(prevState => ({
            showInputActions : Object.assign({}, hiddenInputActions)
        }));
      }
      checkUserState(userState, country){
        if(userState == "Another Country" || country != "United States"){
          this.setState({countryStates: ["Another Country"]});
        }
      }

      checkForNull(userData){
        for (var name in userData) {
          userData[name] = userData[name] != null ? userData[name] : "";
        }
        return userData;
      }

      render(){
        const { classes } = this.props;
        return  <div className="row">
        <div className="col-lg-4 col-md-6">
            <div className="information-container card">
            {/* {
              this.state.restaurants.map((restaurant, index) =>(
                <p key={index}>{restaurant.name} - {restaurant.location}</p>
              ))
            }  */}
                <div className="card-title">Your Information</div>
                <div className="login-form light-form">
                <div className="group">
                      <TextField
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        value={this.state.newUserInfo.first_name}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        // error={!this.state.nameValid}
                        // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 30
                        }}
                      />
                        <div className={this.state.showInputActions.first_name ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="first_name" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="first_name" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="group">
                      <TextField
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        value={this.state.newUserInfo.last_name}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        // error={!this.state.nameValid}
                        // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 30
                        }}
                      />
                      <div className={this.state.showInputActions.last_name ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="last_name" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="last_name" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="group">
                      {/* <InputMask
                          mask="+ 9-999-999-9999"
                          value={this.state.newUserInfo.phone_number}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                          className={classes.textField}
                        >
                          {() => <TextField
                           id="phone_number"
                           label="Mobile Phone"
                           name="phone_number"
                            className={classes.textField}
                            type="text"
                            autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                          }
                        }}
                            />}
                      </InputMask> */}
                                            <TextField
                        id="phone_number"
                        label="Mobile Phone"
                        name="phone_number"
                        value={this.state.newUserInfo.phone_number}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        // error={!this.state.nameValid}
                        // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 20
                        }}
                      />
                        <div className={this.state.showInputActions.phone_number ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="phone_number" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="phone_number" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="group mrg-none">
                      <TextField
                        id="email"
                        label="Email"
                        name="email"
                        value={this.state.newUserInfo.email}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        error={!this.state.validFields.email}
                        helperText={!this.state.validFields.email ? 'Enter valid email address!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 40
                        }}
                      />
                      <div className={this.state.showInputActions.email ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="email" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="email" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="group">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="region"
                        classes = {{
                          root: classes.cssLabel,
                          focused: classes.cssLabelFocused,
                          error: classes.cssLabelError
                        }}
                        >Region</InputLabel>
                        <Select
                          // native
                          value="United States"
                          disabled
                          // onChange={this.handleChange('age')}
                          // value={this.state.newUserInfo.state}
                                      onChange={this.userInfoChange}
                                      onBlur={this.inputInfoFocusedOut}
                          input={
                              <Input
                                  name= 'region'
                                  id= 'region'
                                  disabled
                                  classes={{    
                                  root: classes.cssInput,
                              focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              underline: classes.underline}}
                              />
                            }
                          classes={ {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }}
                          inputProps={{
                            classes: {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }
                          }}
                        >
                        {
                          this.state.countries.map((country, index) =>(
                            <MenuItem key={index} value={country}>{country}</MenuItem>
                          ))
                        }
                        </Select>
                      </FormControl>
                    </div>
                </div>
            </div> 
        </div>
        {/* <div className="col-lg-4 col-md-6">
            <div className="address-container card">
                <div className="card-title">Home Address</div>
                <div className="login-form light-form">
                <div className="group">
                      <TextField
                        id="street"
                        label="Street"
                        name="street"
                        value={this.state.newUserInfo.street}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        // error={!this.state.nameValid}
                        // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 40
                        }}
                      />
                      <div className={this.state.showInputActions.street ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="street" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="street" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="group">
                      <TextField
                        id="street_2"
                        label="Street 2"
                        name="street_2"
                        value={this.state.newUserInfo.street_2}
                        onChange={this.userInfoChange}
                        onBlur={this.inputInfoFocusedOut}
                        onFocus={this.userInputFocus}
                        // error={!this.state.nameValid}
                        // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline,
                            input: "interaction-elem"
                          }
                        }}
                        inputProps={{
                          maxLength: 40
                        }}
                      />
                      <div className={this.state.showInputActions.street_2 ? 'input-actions' : 'input-actions d-none'}>
                          <a href="#" changes-for="street_2" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                          <a href="#" changes-for="street_2" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                      </div>
                    </div>
                    <div className="groups-multiply">
                        <div className="group">
                            <TextField
                                id="city"
                                label="City"
                                name="city"
                                value={this.state.newUserInfo.city}
                                onChange={this.userInfoChange}
                                onBlur={this.inputInfoFocusedOut}
                                onFocus={this.userInputFocus}
                                // error={!this.state.nameValid}
                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                autoComplete="off"
                                className={classes.textField}
                                InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssLabelFocused,
                                    error: classes.cssLabelError
                                },
                                }}
                                InputProps={{
                                classes: {
                                    root: classes.cssInput,
                                    focused: classes.cssLabelFocused,
                                    notchedOutline: classes.notchedOutline,
                                    underline: classes.underline,
                                    input: "interaction-elem"
                                }
                                }}
                                inputProps={{
                                  maxLength: 40
                                }}
                            />
                            <div className={this.state.showInputActions.city ? 'input-actions' : 'input-actions d-none'}>
                              <a href="#" changes-for="city" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                              <a href="#" changes-for="city" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                          </div>
                        </div>
                        <div className="group">
                            <TextField
                                id="post_code"
                                label="Postcode"
                                name="post_code"
                                value={this.state.newUserInfo.post_code}
                                onChange={this.userInfoChange}
                                onBlur={this.inputInfoFocusedOut}
                                onFocus={this.userInputFocus}
                                // error={!this.state.nameValid}
                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                autoComplete="off"
                                className={classes.textField}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssLabelFocused,
                                        error: classes.cssLabelError
                                    },
                                }}
                                InputProps={{
                                  type:"number",
                                    classes: {
                                        root: classes.cssInput,
                                        focused: classes.cssLabelFocused,
                                        notchedOutline: classes.notchedOutline,
                                        underline: classes.underline,
                                        input: "interaction-elem"
                                    }
                                }}
                            />
                            <div className={this.state.showInputActions.post_code ? 'input-actions' : 'input-actions d-none'}>
                              <a href="#" changes-for="post_code" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                              <a href="#" changes-for="post_code" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                          </div>
                        </div>
                    </div>
                    {
                      this.state.newUserInfo.country == "United States" ? <div className="group">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="state"
                        classes = {{
                          root: classes.cssLabel,
                          focused: classes.cssLabelFocused,
                          error: classes.cssLabelError
                        }}
                        >State</InputLabel>
                        <Select
                          value={this.state.newUserInfo.state}
                                      onChange={this.userInfoChange}
                                      onBlur={this.inputInfoFocusedOut}
                          input={
                              <Input
                                  name= 'state'
                                  id= 'state'
                                  classes={{    
                                  root: classes.cssInput,
                              focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              underline: classes.underline}}
                              />
                            }
                          classes={ {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }}
                          inputProps={{
                            classes: {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }
                          }}
                        >
                        {
                          this.state.countryStates.map((state, index) =>(
                            <MenuItem key={index} value={state}>{state}</MenuItem>
                          ))
                        }
                        </Select>
                      </FormControl>
                    </div> : <div className="group mrg-none">
                            <TextField
                                id="state"
                                label="State"
                                name="state"
                                value={this.state.newUserInfo.state}
                                onChange={this.userInfoChange}
                                onBlur={this.inputInfoFocusedOut}
                                onFocus={this.userInputFocus}
                                // error={!this.state.nameValid}
                                helperText={!this.state.validFields ? 'Enter state' : ' '}
                                autoComplete="off"
                                className={classes.textField}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssLabelFocused,
                                        error: classes.cssLabelError
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssInput,
                                        focused: classes.cssLabelFocused,
                                        notchedOutline: classes.notchedOutline,
                                        underline: classes.underline,
                                        input: "interaction-elem"
                                    }
                                }}
                                inputProps={{
                                  maxLength: 40
                                }}
                            />
                            <div className={this.state.showInputActions.state ? 'input-actions' : 'input-actions d-none'}>
                              <a href="#" changes-for="state" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                              <a href="#" changes-for="state" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                          </div>
                        </div>
                    }
                    
                    <div className="group">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="country"
                        classes = {{
                          root: classes.cssLabel,
                          focused: classes.cssLabelFocused,
                          error: classes.cssLabelError
                        }}
                        >Country</InputLabel>
                        <Select
                          // native
                          // value={this.state.age}
                          // onChange={this.handleChange('age')}
                          value={this.state.newUserInfo.country}
                                      onChange={this.onCountryChange}
                                      onBlur={this.inputInfoFocusedOut}
                          input={
                              <Input
                                  name= 'country'
                                  id= 'country'
                                  classes={{    
                                  root: classes.cssInput,
                              focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              underline: classes.underline}}
                              />
                            }
                          classes={ {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }}
                          inputProps={{
                            classes: {
                              root: classes.cssInput,
                              // focused: classes.cssSelectFocused,
                              notchedOutline: classes.notchedOutline,
                              // underline: classes.underline
                            }
                          }}
                        >
                        {
                          this.state.countries.map((country, index) =>(
                            <MenuItem key={index} value={country}>{country}</MenuItem>
                          ))
                        }
                        </Select>
                      </FormControl>
                    </div>
                </div>
            </div>
            
        </div> */}
    </div>
      }
}

export default withStyles(styles)(EditUserInfo);



