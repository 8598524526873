/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
// import { I18n, ConsoleLogger as Logger, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
// import CustomSignOut from '../Login/CustomSignOut';
// import CustomForgotPassword from './CustomForgotPassword';
// import { withGoogle, withAmazon, withFacebook, withOAuth, withAuth0 } from './Provider';
// import { Greetings, AuthPiece, withGoogle, withAmazon, withFacebook, withOAuth, withAuth0, AmplifyTheme } from "aws-amplify-react";

class CustomGreetings extends React.Component {
    constructor(props){
        super(props);

        this.LogOut = this.LogOut.bind(this)
    }

    LogOut(){
        
        Auth.signOut()
            .then(data => {
                console.log(data);
                localStorage.removeItem('currentUserId');
            })
            .catch(err => console.log(err));
    }

    render(){
        return <a href="#" onClick={this.LogOut}>Sign out</a>
    }
}

// class CustomGreetings extends AuthPiece {
//     constructor(props) {
//         super(props);
//         this.state = {};
//         Hub.listen('auth', this);
//         this.onHubCapsule = this.onHubCapsule.bind(this)
//     }

//     componentDidMount() {
//         this._isMounted = true;
//         this.findState();
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//     }

    
//     findState(){
//         if (!this.props.authState && !this.props.authData) {
//             Auth.currentAuthenticatedUser()
//             .then(user => {
//                 this.setState({
//                     authState: 'signedIn',
//                     authData: user,
//                     stateFromStorage: true
//                 })
//             })
//             .catch(err => logger.debug(err));
//         }
//     }

//     onHubCapsule(capsule) {
//         if (this._isMounted) {
//             const { channel, payload, source } = capsule;
//             if (channel === 'auth' && payload.event === 'signIn') {
//                 this.setState({
//                     authState: 'signedIn',
//                     authData: payload.data
//                 })
//                 if  (!this.props.authState) {
//                     this.setState({stateFromStorage: true})
//                 }
//             } else if (channel === 'auth' && payload.event === 'signOut' && (!this.props.authState)) {
//                 this.setState({
//                     authState: 'signIn'
//                 });
//             } 
//         }
//     }

//     inGreeting(name) { return `${I18n.get('Hello')} ${name}`; }
//     outGreeting() { return ''; }


//     userGreetings(theme) {
//         const user = this.props.authData || this.state.authData;
//         const greeting = this.props.inGreeting || this.inGreeting;
//         // get name from attributes first
//         const nameFromAttr = user.attributes? 
//             (user.attributes.name || 
//             (user.attributes.given_name? 
//                 (user.attributes.given_name + ' ' + user.attributes.family_name) : undefined))
//             : undefined;

//         const name = nameFromAttr || user.name || user.username;
//         const message = (typeof greeting === 'function')? greeting(name) : greeting;
//         const { federated } = this.props;

//         return (
//             <span>
//                 {/* <NavItem theme={theme}>{message}</NavItem> */}
//                 {this.renderSignOutButton(theme)}
//             </span>
//         );
//     }

//     renderSignOutButton() {
//         const { federated={} } = this.props;
//         const { google_client_id, facebook_app_id, amazon_client_id, auth0 } = federated;
//         const config = Auth.configure();
//         const { oauth={} } = config;
//         const googleClientId = google_client_id || config.googleClientId;
//         const facebookAppId = facebook_app_id || config.facebookClientId;
//         const amazonClientId = amazon_client_id || config.amazonClientId;
//         const auth0_config = auth0 || oauth.auth0;

//         // if (googleClientId) CustomSignOut = withGoogle(CustomSignOut);
//         // if (facebookAppId) CustomSignOut = withFacebook(CustomSignOut);
//         // if (amazonClientId) CustomSignOut = withAmazon(CustomSignOut);
//         // if (auth0_config) CustomSignOut = withAuth0(CustomSignOut);

//         const stateAndProps = Object.assign({}, this.props, this.state);

//         return <CustomSignOut 
//         {...stateAndProps} 
//         />;
//     }

//     noUserGreetings(theme) {
//         const greeting = this.props.outGreeting || this.outGreeting;
//         const message = (typeof greeting === 'function')? greeting() : greeting;
//         return message? <div>{message}</div> : null;
//     }

//     render() {
//         const { hide } = this.props;
//         // if (hide && hide.includes(Greetings)) { return null; }

//         const authState  = this.props.authState || this.state.authState;
//         const signedIn = (authState === 'signedIn');

//          const theme = this.props.theme || AmplifyTheme;
//         const greeting = signedIn? this.userGreetings(theme) : this.noUserGreetings(theme);
//         if (!greeting) { return null; }

//         return (
//                     <div theme={theme}>
//                         {greeting}
//                     </div>
//         );
//     }
// }

export default CustomGreetings;