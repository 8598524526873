import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router';
import CustomGreetings from './../../../Login/CustomGreetings';
import UserService from '../../../../services/UserService';
import { NotificationManager } from 'react-notifications';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import InfoList from '../info-list/info-list';
import { S3Image } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';

const styles = theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
  });

function UserDetails({classes}) {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();

    const personalFields = [
        {property:'first_name', secondaryText: 'First Name'},
        {property:'last_name', secondaryText: 'Last Name'},
        {property:'email', secondaryText: 'Email'},
        {property:'age', secondaryText: 'Age'},
        {property:'gender', secondaryText: 'Gender'},
        {property:'phone_number', secondaryText: 'Phone Number'},
    ]

    const addressFields = [
        {property:'country', secondaryText: 'Country'},
        {property:'state', secondaryText: 'State'},
        {property:'city', secondaryText: 'City'},
        {property:'street', secondaryText: 'Street'},
        {property:'street_2', secondaryText: 'Street 2'},
        {property:'post_code', secondaryText: 'Postal code'},
    ]

    useEffect(() => {
        async function fetchUser() {
            setLoading(true);
            try{
              const response = await UserService.getServicePortalGetUser(params.id, null);
              if(response.success && response.data != null){
                  setUser(response.data)
              }
              else{
                  NotificationManager.error("Couldn't fetch user. Please, try again later.", 'Error', 2000);
              }

            }
            catch(err){
              console.log(err)
              NotificationManager.error("Couldn't fetch user. Please, try again later.", 'Error', 2000);
            }
            finally{
              setLoading(false);
            }
          }
          fetchUser()
    }, [])

    const userData = () => {
        // const avatarSrc = user.profile_picture && user.profile_picture.url ? user.profile_picture.url : '/static/media/fishing-guy.082e21d0.png';
        // return <S3Image level="private" imgKey={key} />;
        const avatarSrc = '/static/media/fishing-guy.082e21d0.png';
        return(
            <div className="user-data">
                <div className="row">
                    <div className="col-sm-2">
                        <Avatar alt={user.first_name} style={{ height: '140px', width: '140px' }} src={avatarSrc} />
                        {/* <S3Image level="private" level="protected" identityId={'eu-central-1:9ac07b65-3a33-4d29-a554-c64074e1463e'} imgKey={user.profile_picture.key}  /> */}
                        <div className="user-actions d-flex flex-column" style={{marginTop:'30px'}}>
                            <Button variant="contained" color="primary" style={{marginBottom:'10px', width:'60%'}} component={Link} to={`/user-log/${user.id}`}>
                                User Log
                            </Button>
                        </div>
                    </div>
                    <div className="col-sm-3 user-details-info">
                        <div className={classes.demo}>
                            {/* <List dense={false}>
                                {personalFields.map(field => {
                                    return <InfoListItem key={field.property} entity={user} field={field}/>
                                })}
                            </List> */}
                            <InfoList entity={user} fieldsToShow={personalFields}/>
                        </div>    
                    </div>
                    <div className="col-sm-3 user-details-info">
                        <div className={classes.demo}>
                            <InfoList entity={user} fieldsToShow={addressFields}/>
                            {/* <List dense={false}>
                                {addressFields.map(field => {
                                   return <InfoListItem key={field.property} entity={user} field={field}/>
                                })}
                            </List> */}
                        </div>    
                    </div>
                    <div className="col-sm-2 user-details-info">
                        <div className={classes.demo}>
                            <h4>User vessels</h4>
                            <ul className="list-group user-vessels-list">
                                {user.vessels.map(vessel => {
                                   return (
                                    <li key={vessel.id} className="list-group-item">
                                        <Link to={`/user-details/${user.id}/vessel/${vessel.id}`}>{vessel.name}</Link>
                                    </li>
                                    )
                                })}
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="account-main col-lg-10">
        <div className="account-header">
            <div className="sign-out-block">
                <CustomGreetings/>
            </div>
            <div className="cabinet-title">
                <h3>User details</h3>
            </div>
        </div>
        <div className="main-content">
            <div className="card card-info">
                {loading && "Loading..."}
                {!user && !loading && "Cannot fetch user data"}
                {user && userData()}
            </div>

            
        </div>
    </div>
    )
}

export default withStyles(styles)(UserDetails)
