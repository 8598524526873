import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import Footer from "../footer/footer"
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import formStyles from '../../common-styles/js/input-styles'
import BrandLogo from '../CenterLogo/BrandLogo'

const styles = formStyles;

class CustomForgotPassword extends ForgotPassword {
    constructor(props) {
      super(props);
      this._validAuthStates = ["forgotPassword"];
      this.state = { delivery: null,
         intermidiateReset: false,
         email: "",
         password: "",
         confirmPassword: "",
         emailValid: true,
         passwordValid: true,
         confirmPasswordValid: true
        };
        this.onCodeSend = this.onCodeSend.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onNewPasswordSubmit = this.onNewPasswordSubmit.bind(this);

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
    }

    validateEmail(email){
        if (email == null){
            email = this.state.email
        }
        const valid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        this.setState({emailValid: valid});
        return valid;
    }
    validatePassword(password){
        if(password == null){
            password = this.state.password
        }
        const valid = password.length > 0;
        this.setState({passwordValid: valid});
        return valid;
        
    }
    validateConfirmPassword(confirmPassword){
        if(confirmPassword == null){
            confirmPassword = this.state.confirmPassword;
        }
        const valid = this.state.password === confirmPassword;
        this.setState({confirmPasswordValid: valid});
        return valid;
    }

    onEmailChange(e) {
        var val = e.target.value;
        this.setState({email: val});
        this.validateEmail(val);
        this.handleInputChange(e);
    }

    onPasswordChange(e) {
        var val = e.target.value;
        this.setState({password: val});
        this.validatePassword(val);
        this.handleInputChange(e);
    }

    onPasswordConfirmChange(e) {
        var val = e.target.value;
        this.setState({confirmPassword: val});
        this.validateConfirmPassword(val);
        this.handleInputChange(e);
    }

    onCodeSend(){
        // this.state.intermidiateReset = true;
        if(this.validateEmail()){
            this.setState({ intermidiateReset: true });
            this.send();
        }

    }
    onForward(){
        this.setState({ intermidiateReset: false });
    }
    onNewPasswordSubmit(){
        if(this.validateConfirmPassword() && this.validatePassword())
        {
            this.submit();
        }
            
    }

    showComponent(theme){
        const {authData={} } = this.props;

        return (
            <div>
                <BrandLogo />
                <h1 className="font-weight-bold">FirstMate</h1>
                <div className="container main-container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <h3 className="font-weight-bold">{this.state.delivery || authData.username ? (!this.state.intermidiateReset ? "Reset your password" : "Reset code is on the way ...") : "Enter the email address for your account"}</h3>
                            <form className="login-form form-mrg">
                                { this.state.delivery || authData.username ? (!this.state.intermidiateReset ? this.submitView() : this.intermidiateView()) : this.sendView() }
                                <div className="group">
                                { this.state.delivery || authData.username ? 
                                    (this.state.intermidiateReset === false ? <button className="login-form-submit font-weight-bold" type="button" onClick={this.onNewPasswordSubmit}>Set new password</button> : <button className="login-form-submit font-weight-bold btn-mrg" type="button" onClick={this.onForward}>Continue to reset password</button>) :
                                    <button className="login-form-submit font-weight-bold btn-mrg" type="button" onClick={this.onCodeSend}>Send reset code</button>
                                }
                                </div>
                                {!this.state.delivery && !authData.username  ? <div className="group"><a href="#" onClick={() => super.changeState("signIn")}>Back to Sign In</a></div>: null}

                                </form>
                        </div>
                    </div>
                    
                </div>
                <Footer/>
            </div>
        )

    }
    sendView() {
        const { classes } = this.props;
        return (
            <div>
                <div className="group">
                    <TextField
                        id="username"
                        label="Account email"
                        name="username"
                        onChange={this.onEmailChange}
                        margin="normal"
                        error={!this.state.emailValid}
                        helperText={!this.state.emailValid ? 'Email is incorrect!' : ' '}
                        required
                        className={classes.textField}
                        InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                        },
                        }}
                        InputProps={{
                        classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                        }
                        }}
                        inputProps={{
                            maxLength: 30
                        }}
                    />
                </div>
            </div>
        );
    }

    submitView() {
        const { classes } = this.props;
        return (
            <div>
                <div className="group">
                    <TextField
                        id="code"
                        label="Reset password code"
                        name="code"
                        onChange={this.handleInputChange}
                        margin="normal"
                        required
                        autoComplete="off"
                        className={classes.textField}
                        InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                        },
                        }}
                        InputProps={{
                        classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                        }
                        }}
                        inputProps={{
                            maxLength: 30
                        }}
                    />
            </div>
            <div className="group">
                    <TextField
                        id="password"
                        label="New password"
                        name="password"
                        type="password"
                        onChange={this.onPasswordChange}
                        margin="normal"
                        error={!this.state.passwordValid}
                        helperText={!this.state.passwordValid ? 'Enter password' : ' '}
                        required
                        className={classes.textField}
                        InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                        },
                        }}
                        InputProps={{
                        classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                        }
                        }}
                        inputProps={{
                            maxLength: 30
                        }}
                    />
            </div>
            <div className="group">
                    <TextField
                        id="confirmPassword"
                        label="Confirm new password"
                        name="confirmPassword"
                        type="password"
                        onChange={this.onPasswordConfirmChange}
                        margin="normal"
                        error={!this.state.confirmPasswordValid}
                        helperText={!this.state.confirmPasswordValid ? 'Confirm password do not match!' : ' '}
                        required
                        className={classes.textField}
                        InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                        },
                        }}
                        InputProps={{
                        classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                        }
                        }}
                        inputProps={{
                            maxLength: 30
                          }}
                    />
            </div>
        </div>
        );
    }

    intermidiateView(){
        return(
            <div className="group form-info">
                          <div className="notify-text">If your email exists, we will send you the reset
                            code within a couple of minutes.<br/><br/> 
                            Please check your inbox.</div>
                        </div>
        );
    }
}

export default withStyles(styles)(CustomForgotPassword);