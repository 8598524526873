import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function InfoListItem({entity, field}) {
    const entityToProcess = entity || {}
    let primaryText;
    if(typeof(entityToProcess[field.property]) == 'boolean'){
        primaryText = entityToProcess[field.property] === true ? 'Yes' : 'No'
    }
    else{
       primaryText = entityToProcess[field.property] || '-';
    }
    
    

    return(
        <ListItem>
            <ListItemText
                primary={primaryText}
                secondary={field.secondaryText}
            />
        </ListItem>
    )
}

export default InfoListItem
