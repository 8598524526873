import React, { Component } from 'react';
import './App.css';
import UserAccount from './components/account/user-account'

class App extends Component {

  constructor(props, context) {
    super(props, context);
    console.log(props.authState);
  }

  render() {
    if (this.props.authState === "signedIn") {
      return (
        <UserAccount></UserAccount>
      );
    } else {
      return null;
    }
    // return (
    //   <div className="App">Switch
    //   {/* <Authenticator hide={[SignIn]} amplifyConfig={config}>
    //       <BrandLogo />
    //       <CustomSignIn />
    //       <Footer/>
    //     </Authenticator> */}
    //     {/* <header className="App-header">
    //       <img src={logo} className="App-logo" alt="logo" />
    //       <p>
    //         Edit <code>src/App.js</code> and save to reload.
    //       </p>
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Learn React
    //       </a>
    //     </header> */}
    //   </div>
    // );
  }
}

export default App;
// export default withAuthenticator(App,true);
