import React from "react";
import { SignIn } from "aws-amplify-react";
import appStoreLogo from '../../img/itunes-app-store-logo@2x.png';
import googlePlayLogo from '../../img/en_badge_web_generic@2x.png';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import formStyles from '../../common-styles/js/input-styles'
import Footer from "../footer/footer"
import { Auth } from 'aws-amplify';
import BrandLogo from '../CenterLogo/BrandLogo'
// import ValidField from '../../ValidField';
import VesselService from './../../services/VesselService';

const styles = formStyles;

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];

    this.state = {userName: "", password: "", nameValid: true, pswValid: true};

    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onPswChange = this.onPswChange.bind(this);

    VesselService.getServicePortalGetVesselForUser('3a1f0f19-82ac-45c5-9d7a-3f4ec053e110', '28619e1a-0817-4378-b886-f19b052c791c')
      .then(response => {
        console.log('anonymous response', response)
      });
    
    // this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  validatePsw(psw){
    return psw.length > 0;
  }
  validateUserName(name){
    return name.length>0;
  }
  onPswChange(e) {
    var val = e.target.value;
    var valid = this.validatePsw(val);
    this.setState({password: val, pswValid: valid});
    this.handleInputChange(e);
  }
  onUserNameChange(e) {
    var val = e.target.value;
    var valid = this.validateUserName(val);
    this.setState({userName: val, nameValid: valid});
    this.handleInputChange(e);
  }

  showComponent(theme) {
    const { classes } = this.props;
    return (
      <div>
      <BrandLogo />
      <h1 className="font-weight-bold">FirstMate</h1>
      <div className="container main-container">
          <div className="row justify-content-center">
              <div className="col-md-5">
                  <h3 className="font-weight-bold">Log in to your account</h3>
                  <form className="login-form" noValidate autoComplete="off">
                    {/* <ValidField/> */}
                    <div className="group">
                      <TextField
                        id="username"
                        label="Email"
                        name="username"
                        onChange={this.onUserNameChange}
                        error={!this.state.nameValid}
                        helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                          },
                        }}
                        inputProps={{
                          maxLength: 70
                        }}
                      />
                    </div>
                    <div className="group">      
                    <TextField
                    type="password"
                        id="password"
                        label="Password"
                        name="password"
                        onChange={this.onPswChange}
                        error={!this.state.pswValid}
                        helperText={!this.state.pswValid ? 'Password field is required!' : ' '}
                        maxLength="2"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssLabelFocused,
                            error: classes.cssLabelError
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssInput,
                            focused: classes.cssLabelFocused,
                            notchedOutline: classes.notchedOutline,
                            underline: classes.underline
                          },
                        }}
                        inputProps={{
                          maxLength: 70
                        }}
                      />
                    </div>
                      <div className="group">
                        <a href="#" onClick={() => super.changeState("forgotPassword")}>Forgot password?</a>
                      </div>
                      <div className="group">
                          <button className="login-form-submit font-weight-bold" type="button" onClick={() => super.signIn()}>Log In</button>
                      </div>

                      <div className="group form-info">
                        <div className="info-title">Need an account?</div>
                        <div className="info-text">Download the Mercury First Mate iOS or Android App, 
                          and setup your account and system in the app before
                          logging into the web portal.</div>
                      </div>

                    </form>
                    <div className="app-links">
                      <a href="#" className="appstore-link">
                        <img src={appStoreLogo} alt="Download on the Appstore" width="133px" height="46p" />
                      </a>
                      <a href="#" className="gp-link">
                        <img src={googlePlayLogo} alt="Get it on Google Play" width="161px" height="46px" />
                      </a>
                    </div>
              </div>
          </div>
        
      </div>
      <Footer/>
      </div>
    );
  }
}

// export default CustomSignIn
export default withStyles(styles)(CustomSignIn);