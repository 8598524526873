const formStyles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      'font-size': '1.2rem',
    },
  
    cssLabel: {
      color : '#999',
      'font-size': '1.2rem',
      "font-family":"Segoe UI",
      "&:focused:not($disabled):not($error)":{
        "color":"#999"
      },
      "&.focused": {
        color: "#999"
      }
    },
    cssSelectFocused:{
      "background-color":"transparent !important",
      '&:focus':{
        "background-color":"transparent !important"
      }
    },
    cssLabelFocused: {
      "color":"#999",
      // "font-size":"1rem",
      "&:focused:not($disabled):not($error)":{
        "color":"white"
      }
    },
    disabled:{},
    error :{},
    focused:{},
    cssLabelError:{
        color: "red !important"
    },
  
    cssOutlinedInput: {
      // '&$cssFocused $notchedOutline': {
      //   borderColor: `${theme.palette.primary.main} !important`,
      // }
    },
  
    cssInput: {
      color: 'black',
      'font-size':'1.2rem',
      '&:focus':{
        "background-color":"transparent !important"
      }
    },
  
    cssFocused: {},
  
    underline: {
          color: theme.palette.common.white,
          borderBottom: theme.palette.common.white,
          // '&:after': {
          //     borderBottom: `2px solid white`,			
          // },				
          // '&:focused::after': {
          //     borderBottom: `2px solid #white`,
          // },		
          '&:before': {
              borderBottom: `2px solid white`,			
          },
          '&:hover:before': {
            borderBottom: `2px solid red`,			
        },
          '&:hover:not($disabled):not($focused):not($error):before': {
              borderBottom: '2px solid #303f9f !important',
          },
          // '&$disabled:before': {
          //     borderBottom: `2px dotted white`,
          // },
      },
  })

  export default formStyles;