import React, { Component } from 'react';
import logoImg from '../../img/Logo@2x.png';

class BrandLogo extends Component{
      render(){
        return <div className="container logo-container">
            <img src={logoImg} alt="Mercury" />
        </div>
      }
}

export default BrandLogo;