import React, { Component } from 'react';
import tripIcon from '../../img/Trip symbol@2x.png';

class UserTrips extends Component{
 
    state = {
        trips: [
            {
                title: "My Boat 1: Fort Lauderdale - Bimini",
                time: "5h 34m - You did this trip 1 week ago"
            },
            {
                title: "My Boat 1: Bimini - Fort Lauderdale",
                time: "4h 56m - You did this trip 8 days ago"
            },
            {
                title: "My Boat 1: Miami - Bimini",
                time: "5h 26m - You did this trip 12 days ago"
            },
            {
                title: "My Boat 1: Fort Lauderdale - Miami",
                time: "1h 37m - You did this trip 12 days ago"
            },
            {
                title: "My Boat 1: Fort Lauderdale - Bimini",
                time: "5h 34m - You did this trip 1 week ago"
            },
        ],
        showAll: false
    }
      
    showAllClick = event =>{
        event.preventDefault();
        if(this.state.showAll){
            this.setState({showAll: false});
        }
        else{
            this.setState({showAll: true});
        }
    }

      render(){
        return <div className="row">
        <div className="col-lg-8">
            <div className="trips-container card last-container">
                    <div className="card-title">My Latest Trips</div>
                    <div className="trips-list">
                        <ul>
                            {
                                this.state.trips.map((trip, index) =>(
                                    <li key={index}>
                                        <div className={this.state.trips.length > 4 && index > 3 && !this.state.showAll ? "trip-block d-none" : "trip-block"}>
                                            <div className="trip-icon">
                                                <img src={tripIcon} alt="My trip"/>
                                            </div>
                                            <div className="trip-info">
                                                <div className="trip-title">{trip.title}</div>
                                                <div className="trip-time">{trip.time}}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                this.state.trips.length > 4 ? <li>
                                    <a href="#" onClick={this.showAllClick} className="show-more-items">{this.state.showAll ? "Hide" : "Show more"}</a>
                                </li> : null
                            }
                        </ul>
                    </div>
            </div>
        </div>
    </div>
      }
}

export default UserTrips;



