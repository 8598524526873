import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router';
import CustomGreetings from './../../../Login/CustomGreetings';
import { NotificationManager } from 'react-notifications';
import { Avatar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import VesselService from './../../../../services/VesselService';
import InfoList from '../info-list/info-list';

function VesselDetails() {

    const [vessel, setVessel] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();

    useEffect(() => {
        async function fetchUser() {
            setLoading(true);
            try{
              const response = await VesselService.getServicePortalGetVesselForUser(params.id, params.vessel_id);
              if(response.success && response.data != null){
                setVessel(response.data)
              }
              else{
                  NotificationManager.error("Couldn't fetch user. Please, try again later.", 'Error', 2000);
              }
            }
            catch(err){
              console.log(err)
              NotificationManager.error("Couldn't fetch user. Please, try again later.", 'Error', 2000);
            }
            finally{
              setLoading(false);
            }
          }
          fetchUser()
    }, [])

    return (
        <div className="account-main col-lg-10">
        <div className="account-header">
            <div className="sign-out-block">
                <CustomGreetings/>
            </div>
            <div className="cabinet-title d-flex">
                <h3>Vessel Info</h3>
                <div className="breadcrumbs-block">
                <Button variant="contained" color="primary" style={{width:'auto', marginLeft:'20px'}} component={Link} to={`/user-details/${params.id}`}>
                                {'< Back to user'}
                            </Button>
                    {/* <Link to={`/user-info/${params.id}`}>back to user</Link> */}
                </div>
            </div>
        </div>
        <div className="main-content">
            {loading && "Loading..."}
            {!vessel && !loading && "Cannot fetch vessel data"}
            {vessel && vesselData(vessel)}
   
        </div>
    </div>
    )
}

export default VesselDetails;

const generateEngines = (arr) => {
    return arr.map(el => {
        return {id:'sdfsdfdsf'+el, model_name:'test model '+el, horse_powers: 50, brand: 'Siemens'}
    })
}

const vesselData = (vessel) => {

    // const avatarSrc = vessel.vessel_picture && vessel.vessel_picture.url ? vessel.vessel_picture.url : '/static/media/fishing-guy.082e21d0.png';
    const avatarSrc = require("../../../../img/vessel2.svg");
    return(
        <div className="vessel-data">
            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-info">
                        <h5>Main info</h5>
                        <div className="row">
                            <div className="col-md-4 pt-4">
                                <Avatar alt={vessel.name} style={{ width: '100%', height: 'auto' }} src={avatarSrc} />
                            </div>           
                            <div className="col-md-4 vessel-details-info">
                                <div className="pt-4">
                                    <InfoList entity={vessel} fieldsToShow={[
                                        {property:'name', secondaryText: 'Name'},
                                        {property:'vessel_type', secondaryText: 'Vessel Type'},
                                        {property:'product_type', secondaryText: 'Product Type'},
                                        {property:'smartcraft_compatible', secondaryText: 'Smartcraft Compatible'},
                                        
                                        
                                    ]}/>
                                </div>    
                            </div>
                            <div className="col-md-4 vessel-details-info">
                                <div className="pt-4"> 
                                    <InfoList entity={vessel.tds_settings} fieldsToShow={[
                                        {property:'tds_active', secondaryText: 'Tds Active'},
                                        {property:'unlock_setting', secondaryText: 'Unlock Setting'},
                                        {property:'tds_estop', secondaryText: 'Tds Estop'},
                                        {property:'tds_compatible', secondaryText: 'Tds Compatible'}
                                    ]}/>
                                </div>    
                            </div>
                            {/* <div className="col-xl-4 col-lg-6 col-md-4 vessel-details-info pt-4">
                                    <h5>Home Harbour</h5>
                                    <div className="d-flex">
                                        <InfoList entity={vessel.home_harbour} fieldsToShow={[
                                            {property:'harbour_name', secondaryText: 'Harbour Name'},
                                            {property:'street', secondaryText: 'Street'},
                                            {property:'street_2', secondaryText: 'Street 2'}
                                        ]}/>
                                        <InfoList style={{marginLeft: '30px'}} entity={vessel.home_harbour} fieldsToShow={[
                                            {property:'city', secondaryText: 'City'},
                                            {property:'state', secondaryText: 'State'},
                                            {property:'post_code', secondaryText: 'Post Code'},
                                            {property:'country', secondaryText: 'Country'},
                                        ]}/>
                                    </div>

                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-4 pt-4">
                                <h5>Organization</h5>
                                <InfoList entity={vessel.organization} fieldsToShow={[
                                    {property:'name', secondaryText: 'Name'},
                                ]}/>
                            </div>  */}
                        </div>
                    </div>
                </div>
            </div>
           
            {/* {Engines(vessel.engines)} */}
            {Hubs(vessel.hubs)}
            {Fobs(vessel.fobs)}
        </div>
        
    )
}

const Hubs = (hubs) => {
    if(!hubs || hubs.length === 0){
        return null
    }
    return(
        <div className="card card-info mb-3">
            <h5>Hubs for the vessel</h5>
            {hubs.map((hub, index) => {
                return(
                    <div key={hub.id} className="engine-container mt-3">
                        <h5>Hub {index+1}</h5>
                        <div key={hub.id} className="row">
                            
                            <div className="col-md-2 engine-info">
                                <InfoList entity={hub} fieldsToShow={[
                                    {property:'region', secondaryText: 'Region'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={hub} fieldsToShow={[
                                    {property:'product_type', secondaryText: 'Product Type'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={hub} fieldsToShow={[
                                    {property:'serial_number', secondaryText: 'Serial Number'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={hub.firmware_version} fieldsToShow={[
                                    {property:'software_version', secondaryText: 'Software Version'},
                                ]}/>
                            </div>                                
                        </div>
                    </div>
                )
            })}                
        </div>
    )    
}

const Fobs = (fobs) => {
    if(!fobs || fobs.length === 0){
        return null
    }
    return(
        <div className="card card-info mb-3">
            <h5>Fobs for the vessel</h5>
            {fobs.map((fob, index) => {
                return(
                    <div key={fob.id} className="engine-container mt-3">
                        <h5>Fob {index+1}</h5>
                        <div key={fob.id} className="row">
                            
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob} fieldsToShow={[
                                    {property:'region', secondaryText: 'Region'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob} fieldsToShow={[
                                    {property:'product_type', secondaryText: 'Product Type'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob} fieldsToShow={[
                                    {property:'fob_type', secondaryText: 'Fob Type'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob} fieldsToShow={[
                                    {property:'serial_number', secondaryText: 'Serial Number'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob} fieldsToShow={[
                                    {property:'auto_connect_on', secondaryText: 'Auto Connect On'},
                                ]}/>
                            </div>
                            <div className="col-md-2 engine-info">
                                <InfoList entity={fob.firmware_version} fieldsToShow={[
                                    {property:'software_version', secondaryText: 'Software Version'},
                                ]}/>
                            </div>                                
                        </div>
                    </div>
                )
            })}                
        </div>
    )    
}

const Engines = (engines) => {
    if(!engines || engines.length === 0)
    {
        return null
    }

    return(
        <div className="card card-info mb-3">
                <h5>Vessel Engines</h5>
                {engines.map((engine, index) => {
                    return(
                        <div key={engine.id} className="engine-container mt-3">
                            <h5>Vessel {index+1} {engine.model_name && `- ${engine.model_name}`}</h5>
                            <div key={engine.id} className="row">
                                
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'brand', secondaryText: 'Brand'},
                                    ]}/>
                                </div>
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'type', secondaryText: 'Type'},
                                    ]}/>
                                </div>
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'horse_powers', secondaryText: 'Horse Powers'},
                                    ]}/>
                                </div>
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'year_manufactured', secondaryText: 'Year manufactured'},
                                    ]}/>
                                </div>
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'displacement', secondaryText: 'Displacement'},
                                    ]}/>
                                </div>
                                <div className="col-md-2 engine-info">
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'full_throttle_rpm', secondaryText: 'Full Throttle RPM'},
                                    ]}/>
                                </div>
                            </div>
                        </div>
                        
                    )
                    
                })}
                {/* <div className="row">

                    {engines.map(engine => {
                        return(
                            <div key={engine.id} className="col-2 pt-4 engine-info">
                                <h5>{engine.model_name}</h5>
                                    <InfoList entity={engine} fieldsToShow={[
                                        {property:'brand', secondaryText: 'Brand'},
                                        {property:'type', secondaryText: 'Type'},
                                        {property:'horse_powers', secondaryText: 'Horse Powers'},
                                        {property:'year_manufactured', secondaryText: 'Year manufactured'},
                                        {property:'displacement', secondaryText: 'Displacement'},
                                        {property:'full_throttle_rpm', secondaryText: 'Full Throttle RPM'},
                                    ]}/>
                            </div>
                        )
                    })}
                </div> */}
            </div>
    )
}