import React, { Component } from 'react';
import engineImage from '../../img/engine@2x.png';
import VesselService from '../../services/VesselService'
class VesselEngines extends Component{

    state = {
        vesselEngines: null
    }

    async componentWillMount(){
        const vesselId = this.props.vesselId;
        const vesselEngines = await VesselService.getVesselEngines(vesselId)
        if(vesselEngines.success ){
            this.setState({vesselEngines: vesselEngines.data})
        }
    }

    render(){
        return this.state.vesselEngines != null ? <div className="row engines-row">
            {
                this.state.vesselEngines.map((engine, index) =>(
                    <div key={index} className="col-lg-4 col-md-6">
                        <div className="card engine-container">
                            <div className="card-title">Engine {index+1}</div>
                            <div className="engine-name">{engine.model_name}</div>
                            <div className="engine-img">
                                <img src={engineImage} alt="Vessel engine"/>
                            </div>
                            <div className="engine-info">
                                <div className="info-item">
                                    <div className="parameter-name">HP / kW</div>
                                    <div className="parameter-value">{engine.horse_powers}</div>
                                </div>
                                <div className="info-item">
                                    <div className="parameter-name">Model Name</div>
                                    <div className="parameter-value">{engine.model_name}</div>
                                </div>
                                <div className="info-item">
                                    <div className="parameter-name">Year Manufactured</div>
                                    <div className="parameter-value">{engine.year_manufactured}</div>
                                </div>
                                <div className="info-item">
                                    <div className="parameter-name">Serial Number</div>
                                    <div className="parameter-value">{engine.id}</div>
                                </div>
                                <div className="info-item">
                                    <div className="parameter-name">Brand</div>
                                    <div className="parameter-value">{engine.brand}</div>
                                </div>
                                <br/>
                                <br/>
                                <div className="info-item">
                                    <div className="parameter-name">Displacement</div>
                                    <div className="parameter-value">{engine.displacement}</div>
                                </div>
                                <div className="info-item">
                                    <div className="parameter-name">Full throttle RPM</div>
                                    <div className="parameter-value">{engine.full_throttle_rpm}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            } 
        </div> : null
    }
}

export default VesselEngines;