import React from 'react';
import AccountMenu from '../account/account-menu'
import { BrowserRouter as Router, Route } from "react-router-dom";
import UserInfo from './user-info';
import VesselInfo from './vessel-info';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import UserList from './admin-components/user-list/user-list';
import UserDetails from './admin-components/user-details/user-details';
import UserLog from './admin-components/user-log/user-log';
import VesselDetails from './admin-components/vessel-details/vessel-details';
import VesselSearch from './admin-components/vessel-search/vessel-search';
import config from '../../appconfig'
import Amplify from 'aws-amplify';

function UserAccount (){

        
  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      // REQUIRED - Amazon Cognito Identity Pool ID
      userPoolId: config.userPoolId,
      // REQUIRED - Amazon Cognito Region
      region: config.region,
      // OPTIONAL - Amazon Cognito User Pool ID
      identityPoolId: config.identityPoolId,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: config.userPoolWebClientId,
    },
    API: {
      'aws_appsync_graphqlEndpoint': config.graphqlEndpoint,
      'aws_appsync_region': config.region,
      'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    },
  });

        return (<div className="container-fluid account-container">        
                    <Router>
                        <div className="row main-row">
                            <AccountMenu />
                            {/* <Switch> */}
                                <Route exact path="/" component={UserInfo} />
                                <Route path="/vessel" component={VesselInfo} />
                                <Route path="/user-list" component={UserList} />
                                <Route path="/vessel-search" component={VesselSearch} />
                                <Route path="/user-details/:id" component={UserDetails} exact />
                                <Route path="/user-details/:id/vessel/:vessel_id" component={VesselDetails} exact />
                                <Route path="/user-log/:id" component={UserLog} />
                            {/* </Switch> */}
                        </div>
                    </Router>
                
                    <NotificationContainer/>
            </div>
        )
}

export default UserAccount;



