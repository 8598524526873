import React from 'react'
import ValidationForm from '../validation-form/validation-form'

import './validation-form-container.css'

function ValidationFormContainer({onShowSuccessChange}) {

    return (
        <div className="validation-form-container d-flex flex-column justify-content-center">
            <div className="top-block-container">
                <h5 className="container-title">
                    Link your 1<sup>st</sup> Mate account to a dealer / OEM
                </h5>
                <div className="container-description">
                    To link your account to a dealer / OEM and be able to 
                    perform quick setups and run system tests with additional 
                    data parameters, input your email and <br/>
                    Mercury Dealer Number below.
                </div>
                <div className="form-container">
                    <ValidationForm onShowSuccessChange={onShowSuccessChange}/>
                </div>
            </div>
            <div className="bottom-block-container">
                <div className="footer-links">
                    <a href="#" className="form-link">Learn more</a>
                    <a href="#" className="form-link">Support Center</a>
                </div>
            </div>
        </div>
    )
}

export default ValidationFormContainer
