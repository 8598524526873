import React from 'react'
import List from '@material-ui/core/List';
import InfoListItem from './../info-list-item/info-list-item';

function InfoList({entity, fieldsToShow, style={}}) {
    return (
        <List dense={false} style={style}>
            {fieldsToShow.map(field => {
                return <InfoListItem key={field.property} entity={entity} field={field}/>
            })}
        </List>
    )
}

export default InfoList
