import React, { Component } from 'react';
import CustomGreetings from '../Login/CustomGreetings';
import VesselIcons from './vessel-icons';
import EditVesselInfo from './edit-vessel-info';
import VesselEngines from './vessel-engines';
import VesselService from '../../services/VesselService';

class VessellInfo extends Component{

    state = {
        userVessell: null,
        engines: [],
        mainVesselId: null,
        location: {},
    }

    async componentWillMount(){

        const userVessels = await VesselService.getUserVessels();
        if(userVessels.success && userVessels.data != null && userVessels.data.length > 0 ){
            const vesselData = VesselService.toEditObject(userVessels.data[0]);
            console.log('vessel data', userVessels.data);
            this.setState({userVessel : vesselData, engines: userVessels.data[0].engines})
            this.setState({mainVesselId: vesselData.id});

            const vesselLocation = await VesselService.getVesselLocation(vesselData.id)
            if(vesselLocation.success){
                this.setState({location:vesselLocation.data})
            }

        }

        

    }

      render(){
        return  <div className="account-main col-lg-10">
                    <div className="account-header">
                        <div className="sign-out-block">
                            <CustomGreetings/>
                        </div>
                        <div className="cabinet-title">
                            <h3>Vessel Information</h3>
                        </div>
                    </div>
                    <div className="main-content">
                        {/* {
                            this.state.location != null ? <VesselIcons vessel={this.state.userVessel} vesselLocation={this.state.location}/> : <div><p>Loading...</p></div>
                        } */}
                        {
                            this.state.userVessel != null && this.state.location != null ? <EditVesselInfo vesselData={this.state.userVessel} vesselLocation={this.state.location}/> : null
                        }
                        {
                            this.state.mainVesselId != null ? <VesselEngines vesselId={this.state.mainVesselId} /> : null
                        }
                        
                    </div>
                </div>
      }
}

export default VessellInfo;



