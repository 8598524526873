import React from 'react'

function ValidationSuccessContainer() {
    return (
        <div className='validation-success-container text-center'>
            <h5 className="container-title">
                Email successfully validated
            </h5>
            <div className="container-description">
                You now have access to additional functionality in <br/>
                your 1st Mate App reserved for dealers and OEMs.
            </div>
        </div>
    )
}

export default ValidationSuccessContainer
