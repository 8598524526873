import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import  { Redirect } from 'react-router-dom'
import CustomGreetings from './../../../Login/CustomGreetings';
import VesselService from '../../../../services/VesselService';

import './vessel-search.css';

function VesselSearch() {
    const [serialNumber, setSerialNumber] = useState('');    
    const [searchResult, setSearchResult] = useState(null);

    function serialNumberlHandler(serialNumber){
        serialNumber = serialNumber.trim()
        setSerialNumber(serialNumber);
    }
    
    const formSubmit = (e) => {
        e.preventDefault();        

        try{            
            VesselService.getServicePortalSearch(serialNumber).then(response => {
                if(response.success && response.data != null){
                    setSearchResult(response.data);
                }
                else{
                    NotificationManager.error("Couldn't fetch serial number. Please, try again later.", 'Error', 2000);
                }
            });
        }
        catch(err){
            console.log(err)
            NotificationManager.error("Couldn't fetch serial number. Please, try again later.", 'Error', 2000);
        }        
    }
         

    return (
        <div className="account-main col-lg-10">           
            <div className="account-header">
                <div className="sign-out-block">
                    <CustomGreetings/>
                </div>                
                <div className="cabinet-title d-flex">
                    <h3>Vessel Search</h3>                    
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="card card-info pt-3 pl-3 pr-3">
                        <form className="search-form">
                            <div className="group">
                                <TextField
                                id="deviceSerialNumber"
                                label="Device Serial Number"
                                name="deviceSerialNumber"
                                value={serialNumber}
                                onChange={e => serialNumberlHandler(e.target.value)}                    
                                />
                            </div>                            
                            <div className="group">
                                <button className='btn form-button' onClick={formSubmit}>Search</button>                   
                            </div>                              
                        </form>            
                        {searchResult && <Redirect to={`/user-details/${searchResult.user_id}/vessel/${searchResult.vessel_id}`}  />}   
                    </div> 
                </div>   
            </div>    
        </div>
    )   
}

export default VesselSearch;
