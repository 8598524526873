import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './common-styles/css/styles.css';
import './common-styles/css/form-controls.css';
import './common-styles/css/responsive.css';
import Header from './components/header/header'
import AppWithAuth from "./components/Login/AppWithAuth";
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify'
import config from './aws-exports'

Amplify.configure(config)

// ReactDOM.render(<Header />, document.getElementById('siteHeader'));
ReactDOM.render(<AppWithAuth />, document.getElementById('root'));
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


