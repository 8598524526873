import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import formStyles from '../../common-styles/js/light-input-styles'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import {countries} from '../../constants';
import * as constants from '../../constants';
import VesselService from '../../services/VesselService'
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MapContainer from './map-container';

const styles = formStyles;
const hiddenInputActions = {
    name: false,
    length: false,
    beam: false,
    brand: false,
    year_manufactured: false,
    home_harbour_name: false,
    home_harbour_street: false,
    home_harbour_street_2: false,
    home_harbour_city: false,
    home_harbour_post_code: false,
  }
class EditVesselInfo extends Component{
    state = {
        // currentUserId: null,
        newUserVesselInfo: null,
        oldUserVesselInfo: null,
        countryStates: constants.usaStates,
        countries: countries,
        showInputActions : Object.assign({}, hiddenInputActions)
    }
    constructor(props){
        super(props);

        this.inputInfoFocusedOut = this.inputInfoFocusedOut.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onStateSelectBlur = this.onStateSelectBlur.bind(this);
        this.onVesselTypeSelectBlur = this.onVesselTypeSelectBlur.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    handleOutsideClick(event) {
        if(!event.target.classList.contains('interaction-elem')){
            // this.setState({showInputActions : Object.assign({}, hiddenInputActions)});
            // this.setState(prevState => ({
            //     showInputActions : Object.assign({}, hiddenInputActions)
            // }));
            this.hideAllActions();
        }
    }

    async componentWillMount(){
        const userVesselData = this.props.vesselData;
        if(userVesselData != null){
            this.setState({newUserVesselInfo : userVesselData, oldUserVesselInfo: Object.assign({}, userVesselData)})
        }
    }

    // handleClickOutside = event => {
    //     const domNode = ReactDOM.findDOMNode(this);
    
    //     if (!domNode || !domNode.contains(event.target)) {
    //         // var elem = $('.input-actions');
    //         // console.log(elem);
    //     }
    // }

    onInputChange (event){
        let updatedInfo = this.state.newUserVesselInfo;
        updatedInfo[event.target.name] = event.target.value;
        this.setState({ newUserVesselInfo: updatedInfo});
    }

    async updateVessel(vesselParam){
        this.showActions(vesselParam);
        // this.hideActions(changingParam);

        let updatedVesselInfo = this.state.oldUserVesselInfo;
        updatedVesselInfo[vesselParam] = this.state.newUserVesselInfo[vesselParam];
        const responseData = await VesselService.updateVesselInformation(updatedVesselInfo);
        if(responseData.success){
        //   this.hideActions(vesselParam);
            NotificationManager.success('Your changes applied successfully!', 'Success',1000);
            this.setState({ oldUserVesselInfo: updatedVesselInfo});
        }
        else{
            NotificationManager.error("Couldn't update information. Try later.", 'Error',2000);
        }
    }

    async onStateSelectBlur(event){
        event.preventDefault();
        this.updateVessel("home_harbour_state");
    }

    async onVesselTypeSelectBlur(event){
        event.preventDefault();
        this.updateVessel("vessel_type");
    }

    async applyChanges(event){
        event.preventDefault();
        event.stopPropagation();
        const changingParam = event.currentTarget.getAttribute("changes-for");
        this.updateVessel(changingParam);
    }

    discardChanges = event =>{
        event.preventDefault();
        event.stopPropagation();
        const changingParam = event.currentTarget.getAttribute("changes-for");
        this.showActions(changingParam);
        let updatedVesselInfo = this.state.newUserVesselInfo;
        updatedVesselInfo[changingParam] = this.state.oldUserVesselInfo[changingParam];
        this.setState({ newUserVesselInfo: updatedVesselInfo});
        this.hideActions(changingParam);
    }
    userInputFocus = event =>{
        this.showActions(event.target.name);
        // const changingParam = event.currentTarget.getAttribute("changes-for");
    }
    userActionsFocusOut(event){

    }
    inputInfoFocusedOut(event){
        // this.hideActions(event.target.name);
    }

    async onCountryChange(event){
        let updatedInfo = this.state.newUserVesselInfo;
        if(event.target.value === "United States"){ 
          updatedInfo["home_harbour_state"] = constants.usaStates[0];
        }
        
        //updating
        updatedInfo[event.target.name] = event.target.value;
        this.setState({ newUserVesselInfo: updatedInfo});

        let updatedVesselInfo = this.state.oldUserVesselInfo;
        updatedVesselInfo["home_harbour_country"] = updatedInfo["home_harbour_country"];
        updatedVesselInfo["home_harbour_state"] = updatedInfo["home_harbour_state"];
        const responseData = await VesselService.updateVesselInformation(updatedVesselInfo);
        if(responseData.success != null){
          this.setState({ oldUserVesselInfo: updatedVesselInfo});
          NotificationManager.success('Your changes applied successfully!', 'Success',1000);
        }
        else{
            NotificationManager.error("Couldn't update information. Try later.", 'Error',2000);
        }

      }

      showActions(param){
        // let showActions = this.state.showInputActions;
        let showActions = Object.assign({}, hiddenInputActions)
        showActions[param] = true;
        this.setState({showInputActions : showActions});
      }
      hideActions(param){
        let showActions = this.state.showInputActions;
        showActions[param] = false;
        this.setState({showInputActions : showActions});
      }
      hideAllActions(){
        this.setState(prevState => ({
            showInputActions : Object.assign({}, hiddenInputActions)
        }));
      }
    //   checkHarbourState(state, country){
    //     if(state == "Another Country" || country != 'United States'){
    //       this.setState({countryStates: ["Another Country"]});
    //     }
    //   }


    render(){
        const{classes} = this.props;
        return <div>
            {
                this.state.newUserVesselInfo != null ?(
                    <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="card vessel-details-container">
                                    <div className="card-title">Vessel Details</div>
                                    <div className="login-form light-form">
                                        <div className="group">      
                                            <TextField
                                                id="name"
                                                label="Name"
                                                name="name"
                                                value={this.state.newUserVesselInfo.name}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.cssLabelFocused,
                                                        notchedOutline: classes.notchedOutline,
                                                        underline: classes.underline,
                                                        input: "interaction-elem"
                                                    },
                                                }}
                                                inputProps={{
                                                    maxLength: 40
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.name ? 'input-actions interaction-elem' : 'input-actions interaction-elem d-none'}>
                                                <a href="#" changes-for="name" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check interaction-elem"></i></a>
                                                <a href="#" changes-for="name" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times interaction-elem"></i></a>
                                            </div>
                                        </div>
                                    
                                        <div className="group">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="vessel_type"
                                            classes = {{
                                            root: classes.cssLabel,
                                            focused: classes.cssLabelFocused,
                                            error: classes.cssLabelError
                                            }}
                                            >Type</InputLabel>
                                            <Select
                                              value={this.state.newUserVesselInfo.vessel_type}
                                                onChange={this.onInputChange}
                                                onBlur={this.onVesselTypeSelectBlur}
                                            input={
                                                <Input
                                                    name= 'vessel_type'
                                                    id= 'vessel_type'
                                                    classes={{    
                                                    root: classes.cssInput,
                                                focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                underline: classes.underline}}
                                                />
                                                }
                                            classes={ {
                                                root: classes.cssInput,
                                                // focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                // underline: classes.underline
                                                }}
                                            inputProps={{
                                                classes: {
                                                root: classes.cssInput,
                                                // focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                // underline: classes.underline
                                                }
                                            }}
                                            >

                                            {
                                            constants.vesselTypes.map((type, index) =>(
                                                <MenuItem key={index} value={type.value}>{type.title}</MenuItem>
                                            ))
                                            }
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className="group">      
                                            <TextField
                                                id="length"
                                                label="length"
                                                name="length"
                                                value={this.state.newUserVesselInfo.length}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                type="number"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    maxLength: 10
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.length ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="length" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="length" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div className="group">      
                                        <TextField
                                                id="beam"
                                                label="Beam"
                                                name="beam"
                                                value={this.state.newUserVesselInfo.beam}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                type="number"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    maxLength: 10
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.beam ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="beam" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="beam" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div className="group">      
                                        <TextField
                                                id="brand"
                                                label="Brand"
                                                name="brand"
                                                value={this.state.newUserVesselInfo.brand}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    maxLength: 40
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.brand ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="brand" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="brand" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div className="group">      
                                        <TextField
                                                id="year_manufactured"
                                                label="Year"
                                                name="year_manufactured"
                                                value={this.state.newUserVesselInfo.year_manufactured}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                type="number"
                                                
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    min: 1,
                                                    max:2019
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.year_manufactured ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="year_manufactured" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="year_manufactured" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card vessel-harbour-container">
                                    <div className="card-title">Home Harbour</div>
                                    <div className="login-form light-form">
                                        <div className="group">      
                                        <TextField
                                                id="home_harbour_name"
                                                label="Harbour name"
                                                name="home_harbour_name"
                                                value={this.state.newUserVesselInfo.home_harbour_name}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    maxLength: 40
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.home_harbour_name ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="home_harbour_name" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="home_harbour_name" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div className="group">      
                                        <TextField
                                                id="home_harbour_street"
                                                label="Street"
                                                name="home_harbour_street"
                                                value={this.state.newUserVesselInfo.home_harbour_street}
                                                onChange={this.onInputChange}
                                                onBlur={this.inputInfoFocusedOut}
                                                onFocus={this.userInputFocus}
                                                // error={!this.state.nameValid}
                                                // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                autoComplete="off"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssLabelFocused,
                                                    error: classes.cssLabelError
                                                },
                                                }}
                                                InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.cssLabelFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    underline: classes.underline,
                                                    input: "interaction-elem"
                                                }
                                                }}
                                                inputProps={{
                                                    maxLength: 40
                                                  }}
                                            />
                                            <div className={this.state.showInputActions.home_harbour_street ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="home_harbour_street" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="home_harbour_street" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div className="group">
                                        <TextField
                                            id="home_harbour_street_2"
                                            label="Street 2"
                                            name="home_harbour_street_2"
                                            value={this.state.newUserVesselInfo.home_harbour_street_2}
                                            onChange={this.onInputChange}
                                            onBlur={this.inputInfoFocusedOut}
                                            onFocus={this.userInputFocus}
                                            // error={!this.state.nameValid}
                                            // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                            autoComplete="off"
                                            className={classes.textField}
                                            InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel,
                                                focused: classes.cssLabelFocused,
                                                error: classes.cssLabelError
                                            },
                                            }}
                                            InputProps={{
                                            classes: {
                                                root: classes.cssInput,
                                                focused: classes.cssLabelFocused,
                                                notchedOutline: classes.notchedOutline,
                                                underline: classes.underline,
                                                input: "interaction-elem"
                                            }
                                            }}
                                            inputProps={{
                                                maxLength: 40
                                              }}
                                        />
                                        <div className={this.state.showInputActions.home_harbour_street_2 ? 'input-actions' : 'input-actions d-none'}>
                                            <a href="#" changes-for="home_harbour_street_2" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                            <a href="#" changes-for="home_harbour_street_2" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                        </div>
                                        </div>
                                        <div className="groups-multiply">
                                            <div className="group">
                                                <TextField
                                                    id="home_harbour_city"
                                                    label="City"
                                                    name="home_harbour_city"
                                                    value={this.state.newUserVesselInfo.home_harbour_city}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.inputInfoFocusedOut}
                                                    onFocus={this.userInputFocus}
                                                    // error={!this.state.nameValid}
                                                    // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                    autoComplete="off"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel,
                                                        focused: classes.cssLabelFocused,
                                                        error: classes.cssLabelError
                                                    },
                                                    }}
                                                    InputProps={{
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.cssLabelFocused,
                                                        notchedOutline: classes.notchedOutline,
                                                        underline: classes.underline,
                                                        input: "interaction-elem"
                                                    }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 30
                                                      }}
                                                />
                                                <div className={this.state.showInputActions.home_harbour_city ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="home_harbour_city" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="home_harbour_city" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                            </div>
                                            <div className="group">
                                                <TextField
                                                    id="home_harbour_post_code"
                                                    label="Postcode"
                                                    name="home_harbour_post_code"
                                                    value={this.state.newUserVesselInfo.home_harbour_post_code}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.inputInfoFocusedOut}
                                                    onFocus={this.userInputFocus}
                                                    type="number"
                                                    // error={!this.state.nameValid}
                                                    // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                    autoComplete="off"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssLabelFocused,
                                                            error: classes.cssLabelError
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.cssInput,
                                                            focused: classes.cssLabelFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                            underline: classes.underline,
                                                            input: "interaction-elem"
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 12,
                                                        // min:1,
                                                        // max:9999999
                                                    }}
                                                />
                                                <div className={this.state.showInputActions.home_harbour_post_code ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="home_harbour_post_code" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="home_harbour_post_code" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.newUserVesselInfo.home_harbour_country == "United States" ? <div className="group">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="home_harbour_state"
                                                classes = {{
                                                root: classes.cssLabel,
                                                focused: classes.cssLabelFocused,
                                                error: classes.cssLabelError
                                                }}
                                                >State</InputLabel>
                                                <Select
                                                  value={this.state.newUserVesselInfo.home_harbour_state}
                                                    // onChange={this.onInputChange}
                                                    onBlur={this.onStateSelectBlur}
                                                    changes-for="home_harbour_state"
                                                    input={
                                                        <Input
                                                            onChange={this.onInputChange}
                                                            name= 'home_harbour_state'
                                                            id= 'home_harbour_state'
                                                            changes-for="home_harbour_state"
                                                            classes={{    
                                                            root: classes.cssInput,
                                                            focused: classes.cssSelectFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                            underline: classes.underline}}
                                                        />
                                                    }
                                                classes={ {
                                                    root: classes.cssInput,
                                                    // focused: classes.cssSelectFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    // underline: classes.underline
                                                    }}
                                                inputProps={{
                                                    classes: {
                                                    root: classes.cssInput,
                                                    // focused: classes.cssSelectFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                    // underline: classes.underline
                                                    }
                                                }}
                                                >
                                                {
                                                this.state.countryStates.map((state, index) =>(
                                                    <MenuItem key={index} value={state}>{state}</MenuItem>
                                                ))
                                                }
                                                </Select>
                                            </FormControl>
                                            </div> :                                             <div className="group">
                                                <TextField
                                                    id="home_harbour_state"
                                                    label="State"
                                                    name="home_harbour_state"
                                                    value={this.state.newUserVesselInfo.home_harbour_state}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.inputInfoFocusedOut}
                                                    onFocus={this.userInputFocus}
                                                    // error={!this.state.nameValid}
                                                    // helperText={!this.state.nameValid ? 'Email field is required!' : ' '}
                                                    autoComplete="off"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssLabelFocused,
                                                            error: classes.cssLabelError
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.cssInput,
                                                            focused: classes.cssLabelFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                            underline: classes.underline,
                                                            input: "interaction-elem"
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 40
                                                      }}
                                                />
                                                <div className={this.state.showInputActions.home_harbour_state ? 'input-actions' : 'input-actions d-none'}>
                                                <a href="#" changes-for="home_harbour_state" onClick={this.applyChanges} className="approve-changes interaction-elem"><i className="fas fa-check"></i></a>
                                                <a href="#" changes-for="home_harbour_state" onClick={this.discardChanges} className="discard-changes interaction-elem"><i className="fas fa-times"></i></a>
                                            </div>
                                            </div>

                                        }
                                        
                                        <div className="group">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="home_harbour_country"
                                            classes = {{
                                            root: classes.cssLabel,
                                            focused: classes.cssLabelFocused,
                                            error: classes.cssLabelError
                                            }}
                                            >Country</InputLabel>
                                            <Select
                                                value={this.state.newUserVesselInfo.home_harbour_country}
                                                onChange={this.onCountryChange}
                                                onBlur={this.inputInfoFocusedOut}
                                            input={
                                                <Input
                                                    name= 'home_harbour_country'
                                                    id= 'home_harbour_country'
                                                    classes={{    
                                                    root: classes.cssInput,
                                                focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                underline: classes.underline}}
                                                />
                                                }
                                            classes={ {
                                                root: classes.cssInput,
                                                // focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                // underline: classes.underline
                                                }}
                                            inputProps={{
                                                classes: {
                                                root: classes.cssInput,
                                                // focused: classes.cssSelectFocused,
                                                notchedOutline: classes.notchedOutline,
                                                // underline: classes.underline
                                                }
                                            }}
                                            >
                                            {
                                            this.state.countries.map((country, index) =>(
                                                <MenuItem key={index} value={country}>{country}</MenuItem>
                                            ))
                                            }
                                            </Select>
                                        </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12">
                                <div className="card vessel-location-container">
                                    <div className="card-title">Vessel Location</div>
                                    <div className="location-info">
                                            <span>Last logged location:</span> {this.props.vesselLocation.latitude}, {this.props.vesselLocation.longitude}  <br/>
                                            <br/>
                                    </div>
                                    <div id="map">
                                            <MapContainer location={this.props.vesselLocation}/>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                ):null
            }
        </div>
    }
}

export default withStyles(styles)(EditVesselInfo);