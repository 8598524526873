import React, {useState, useEffect} from 'react'
import CustomGreetings from './../../../Login/CustomGreetings';
import UserService from '../../../../services/UserService';
import { NotificationManager } from 'react-notifications';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import formStyles from '../../../../common-styles/js/light-input-styles'
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import './user-list.css';

const UserList = ({classes}) => {
    const [users, setUsers] = useState([]);
    const [usersToDisplay, setUsersToDisplay] = useState([])
    const [loading, setLoading] = useState(false);
    const [queryEmail, setQueryEmail] = useState('')

    useEffect(() => {
        async function fetchUsers() {
          setLoading(true);
          try{
            const response = await UserService.getServicePortalListUsers();
            if(response.success && response.data != null){
                setUsers(response.data)
                setUsersToDisplay(response.data);
            }
            else{
                NotificationManager.error("Couldn't fetch users. Please, try again later.", 'Error', 2000);
            }
          }
          catch(err){
            console.log(err)
            NotificationManager.error("Couldn't fetch users. Please, try again later.", 'Error', 2000);
          }
          finally{
            setLoading(false);
          }
        }
    
        fetchUsers()
      }, [])

      const onQueryChange = (query) => {
          
          setQueryEmail(query);
          if(query){
            const filteredUsers = users.filter(user => user.email.includes(query));
            setUsersToDisplay(filteredUsers);
          }
          else{
            setUsersToDisplay(users);
          }
      }

    const userTable = () => {
        return <div className="row table-row">
            <div className="col-12">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersToDisplay.map((user, index) => {
                            return <tr key={user.id}>
                                <th scope="row">{index+1}</th>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>
                                    <Button  target="_blank" variant="contained" color="primary" component={Link} to={`user-details/${user.id}`}>
                                        Details
                                    </Button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        
    }

    return (
        <div className="account-main col-lg-10">
            <div className="account-header">
                <div className="sign-out-block">
                    {/* <a href="#">Sign out</a> */}
                    <CustomGreetings/>
                </div>
                <div className="cabinet-title">
                    <h3>Portal users</h3>
                </div>
            </div>
            <div className="main-content">
                <div className="card user-list-card card-info mb-4">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="search-bar d-flex mb-5 justify-content-between">
                                <TextField
                                    id="email"
                                    label="search by email"
                                    name="email"
                                    value={queryEmail}
                                    onChange={e => onQueryChange(e.target.value)}
                                    autoComplete="off"
                                    className={classes.textField}
                                    InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssLabelFocused,
                                        error: classes.cssLabelError
                                    },
                                    }}
                                    InputProps={{
                                    classes: {
                                        root: classes.cssInput,
                                        focused: classes.cssLabelFocused,
                                        notchedOutline: classes.notchedOutline,
                                        underline: classes.underline,
                                        input: "interaction-elem"
                                    }
                                    }}
                                    inputProps={{
                                    maxLength: 40
                                    }}
                                />
                                {/* <button type="button" onClick={searchUser}>Search</button> */}
                                {/* <Button variant="outlined" color="primary" style={{marginLeft:'5px'}} onClick={searchUser}>Search</Button> */}
                            </div>
                        </div>
                    </div>

                    {loading && "Loading..."}
                    {!loading && usersToDisplay.length === 0 && "No entries"}
                    {!loading && usersToDisplay.length > 0 && userTable()}
                </div>

                
            </div>
        </div>
    )
}

export default withStyles(formStyles)(UserList)
