import React, { Component } from 'react';
import axios from 'axios';
import AppConfig from '../../appconfig';

class VesselIcons extends Component{
    state = {
        locationTitle:"-",
        airTemperature:"",
        windSpeed:"",
        windDirection:"",
        clouds:"",
        waterTemperature:"",
    }

    componentWillMount(){
        this.getLocationTitle(this.props.vesselLocation.latitude,this.props.vesselLocation.longitude);
        this.getLocationWeather(this.props.vesselLocation.latitude,this.props.vesselLocation.longitude);
        this.getWaterTemperature(this.props.vesselLocation.latitude,this.props.vesselLocation.longitude);
    }

    getLocationTitle(latitude, longitude){
        let locationTitle = "-";
        try{
            axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&sensor=false&language=en&key='+AppConfig.googleMapAPIKey)
            // axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=59.733983,10.235845&sensor=false&language=en&key='+AppConfig.googleMapAPIKey)
                .then(response => {
                    const data = response.data;
                    if(data.status === 'OK'){
                        if(data.results[1]){
                            const cityName = this.getCityName(data.results);
                            console.log(cityName);
                            this.setState({locationTitle: cityName});
                        }
                    }
                })
        }
        catch(ex){
            console.log(ex);
        }

    }

    getLocationWeather(latitude, longitude){
        axios.get('https://api.met.no/weatherapi/locationforecast/1.9/?lat='+latitude+'&lon='+longitude)
        .then(response => {
            const xml = this.parseXmlToDOM(response.data);
            // console.log(xml);
            const airTemperature = this.getAirTemperature(xml);
            const windSpeed = this.getWindSpeed(xml);
            const shortWindDirectionName = this.getWindDirection(xml);
            const clouds = this.getClouds(xml);
            this.setState({airTemperature: airTemperature, windDirection: shortWindDirectionName, clouds: clouds, windSpeed: windSpeed});
        })
    }

    getAirTemperature(xml){
        let airTemperature = "";
        try{
            airTemperature = xml.querySelector('temperature').getAttribute('value');
        }
        catch(ex){
            console.log(ex)
        }
        return airTemperature;
    }
    getWaterTemperature(latitude, longitude){
        let waterTemperature = "";
        axios.get('https://api.met.no/weatherapi/oceanforecast/0.9/?lat='+latitude+'&lon='+longitude)
        .then(response => {
            try{
                const xml = this.parseXmlToDOM(response.data);
                // console.log(xml);
                waterTemperature = xml.querySelector('seaTemperature').innerHTML;
                this.setState({waterTemperature: waterTemperature});
            }
            catch(ex){
                console.log(ex);
            }
        })
    }

    getClouds(xml){
        let cloudiness = "-";
        try{
            const cloudsPercent = xml.querySelector('cloudiness').getAttribute('percent');
            const percentNumber = parseFloat(cloudsPercent);
            if(percentNumber <= 25){
                cloudiness = 'Sunny'
            }
            else if(percentNumber <= 50){
                cloudiness = 'Mostly sunny'
            }
            else if(percentNumber <= 75){
                cloudiness = 'Mostly cloudy'
            }
            else{
                cloudiness = 'Cloudy'
            }
        }
        catch(ex){
            console.log(ex);
        }
        return cloudiness;
    }

    getWindSpeed(xml){
        let windSpeed = "";
        try{
            windSpeed = xml.querySelector('windSpeed').getAttribute('mps');
            if(windSpeed != ''){
                windSpeed += "MPS"
            }
        }
        catch(ex){
            console.log(ex);
        }
        return windSpeed;
    }

    getWindDirection(xml){
        let shortName = "-";
        try{
            const windDirectionDeg = xml.querySelector('windDirection').getAttribute('deg');
            let input = parseFloat(windDirectionDeg) / 11.25;
            input = input+.5|0;
            const name = this.calcPoint(input);
            shortName = this.getShortName(name);
            
        }
        catch(ex){
            console.log(ex);
        }
        return shortName;
    }

    calcPoint(input) {
        var j = input % 8,
            input = (input / 8)|0 % 4,
            cardinal = ['north', 'east', 'south', 'west'],
            pointDesc = ['1', '1 by 2', '1-C', 'C by 1', 'C', 'C by 2', '2-C', '2 by 1'],
            str1, str2, strC;
     
        str1 = cardinal[input];
        str2 = cardinal[(input + 1) % 4];
        strC = (str1 === cardinal[0] | str1 === cardinal[2]) ? str1 + str2 : str2 + str1;
        return pointDesc[j].replace(1, str1).replace(2, str2).replace('C', strC);
    }
    getShortName(name) {
        return name.replace(/north/g, "N").replace(/east/g, "E").replace(/south/g, "S").replace(/west/g, "W").replace(/by/g, "b").replace(/[\s-]/g, "");
    }

    parseXmlToDOM(data){
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');
        return xml;
    }

    getCityName(results){
        if (results[1] || results[0]) {
            var country = null, countryCode = null, city = null, cityAlt = null;
            var c, lc, component;
            for (var r = 0, rl = results.length; r < rl; r += 1) {
                var result = results[r];
    
                if (!city && (result.types[0] === 'locality' || result.types[0] === 'street_address')) {
                    for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                        component = result.address_components[c];
    
                        if (component.types[0] === 'locality') {
                            city = component.long_name;
                            break;
                        }
                    }
                }
                else if (!city && !cityAlt && result.types[0] === 'administrative_area_level_1') {
                    for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                        component = result.address_components[c];
    
                        if (component.types[0] === 'administrative_area_level_1') {
                            cityAlt = component.long_name;
                            break;
                        }
                    }
                } else if (!country && result.types[0] === 'country') {
                    country = result.address_components[0].long_name;
                    countryCode = result.address_components[0].short_name;
                }
    
                if (city && country) {
                    break;
                }
            }
            if(city != null){
                return city;
            }
            else return country;

            //console.log("City: " + city + ", City2: " + cityAlt + ", Country: " + country + ", Country Code: " + countryCode);
        }
    }

    render(){
        return <div className="row">
        <div className="col-12">
            <div className="vessel-information-container card">
                <div className="card-title">Your Information</div>
                <div className="vessel-info-list">
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/ic_verified_user_24px@2x.png")} alt="user" />
                        </div>
                        <div className="info-title">TDS ACTIVE</div>
                    </div>
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/ic_room_24px@2x.png")} alt="location" />
                        </div>
                        <div className="info-title">{this.state.locationTitle}</div>
                    </div>
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/Sun@2x.png")} alt="temperature" />
                        </div>
                        <div className="info-title">{this.state.airTemperature} °C</div>
                    </div>
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/Wave@2x.png")} alt="waves" />
                        </div>
                        <div className="info-title">{this.state.windSpeed} {this.state.windDirection}</div>
                    </div>
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/ic_wb_cloudy_24px@2x.png")} alt="cloudy" />
                        </div>
                        <div className="info-title">{this.state.clouds}</div>
                    </div>
                    <div className="vessel-info-item">
                        <div className="info-icon">
                            <img src={require("../../img/ic_pool_24px@2x.png")} alt="water temperature" />
                        </div>
                        <div className="info-title">{this.state.waterTemperature != '' ? this.state.waterTemperature + ' °C' : '-'}</div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    }
}

export default VesselIcons;