// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e346909b-7d7d-4982-ac79-f3c22557bd04",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_eVoAAwnes",
    "aws_user_pools_web_client_id": "4bl3kghjdr5q42hj5cv5klj6bp",
    "aws_content_delivery_bucket": "myapp2-20190123160206-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://myapp2-20190123160206-hostingbucket.s3-website-us-east-1.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://hpixzufeezfbrfqrzg7g33zble.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
